import { Injectable } from '@angular/core';
import { User } from '../../profile/owner/users/users-list/users-list.component';
import { Observable, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ErrorLoggingService } from './error-logging.service';
import { HelpersService } from './helpers.service';
import { RealTimeCommunicationService } from './real-time-communication.service';
import { VersionService } from './version.service';

@Injectable({
  providedIn: 'root',
})
export class UsersServiceService {
  private usersUpdated = new Subject<User[]>();
  private workersUpdated = new Subject<User[]>();
  private usersSetsList = new Subject<User[]>();
  private taskUpdated = new Subject<any>();
  private workersHoursUpdated = new Subject<any>();
  private usersProgressUpdated = new Subject<any>();
  private foundUsersUpdated = new Subject<User[]>();
  // url = `https://test.lingking.com.pl:3001/api/users/`
  // url = 'https://backend-int.linget.it/api/users/'
  url;
  users: User[] = [];
  textToSearch = '';
  socket = null;
  workersAvailabilityUpdated = new Subject<any>();

  constructor(
    private realTimeCommunicationService: RealTimeCommunicationService,
    private http: HttpClient,
    private helpers: HelpersService,
    private authService: AuthenticationService,
    private errorLoggingService: ErrorLoggingService,
    private versionService: VersionService,
  ) {
    this.url = this.versionService.url + '/api/users/';
  }
  setUsersSettings(userId, setting) {
    this.http
      .put(
        this.url + 'setsetting/' + userId,
        { setting: setting },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => { });
  }
  addWorkersTasks(userId, taskToAdd) {
    delete taskToAdd?.status;
    delete taskToAdd?.dontDisplay;
    delete taskToAdd?.recipient;

    this.taskUpdated.next('updateOk');
    this.http
      .put(
        this.url + 'addworkerstask/' + userId,
        { task: taskToAdd },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: users-service.service.ts ~ line 59 ~ UsersServiceService ~ .subscribe ~ response',
          response,
        );
      });
  }
  updateTasksCompletion(userId, taskToUpdateId, newCompletion) {
    this.http
      .put(
        this.url + 'updatetaskscompletion/' + userId,
        { taskToUpdateId: taskToUpdateId, completion: newCompletion },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: users-service.service.ts ~ line 59 ~ UsersServiceService ~ .subscribe ~ response',
          response,
        );
        // this.taskUpdated.next("updateOk");
      });
  }
  updateTasksData(userId, taskToUpdateId, taskToUpdate) {
    delete taskToUpdateId?.newComment;
    const _taskToUpdate = this.helpers.clone(taskToUpdate);
    this.taskUpdated.next('updateOk');
    _taskToUpdate.comments.forEach((comment) => {
      comment.new = false;
    });
    this.http
      .put(
        this.url + 'updatetasksdata/' + userId,
        { taskToUpdateId: taskToUpdateId, taskToUpdate: _taskToUpdate },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: users-service.service.ts ~ line 59 ~ UsersServiceService ~ .subscribe ~ response',
          response,
        );
      });
  }
  updateTasksScheduledDate(userId, taskToUpdateId, newScheduleDate) {
    delete taskToUpdateId?.newComment;

    this.taskUpdated.next('updateOk');
    this.http
      .put(
        this.url + 'updatetasksscheduleddate/' + userId,
        {
          taskToUpdateId: taskToUpdateId,
          dateScheduledToUpdate: newScheduleDate,
        },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: users-service.service.ts ~ line 59 ~ UsersServiceService ~ .subscribe ~ response',
          response,
        );
      });
  }
  updateTasksCommentsVisibility(userId, taskToUpdate) {
    delete taskToUpdate?.newComment;

    this.http
      .put(
        this.url + 'updatetaskscommentsvisibility/' + userId,
        {
          taskToUpdateId: taskToUpdate.id,
          comments: taskToUpdate.comments,
        },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: users-service.service.ts ~ line 59 ~ UsersServiceService ~ .subscribe ~ response',
          response,
        );
        this.taskUpdated.next('updateOk');
      });
  }
  updateTasksText(userId, taskToUpdateId, textToUpdate) {
    delete taskToUpdateId?.newComment;

    this.taskUpdated.next('updateOk');
    this.http
      .put(
        this.url + 'updatetaskstext/' + userId,
        {
          taskToUpdateId: taskToUpdateId,
          textToUpdate: textToUpdate,
        },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: users-service.service.ts ~ line 59 ~ UsersServiceService ~ .subscribe ~ response',
          response,
        );
      });
  }
  addCommentToTask(userId, taskToUpdateId, newComment, recipients) {
    delete taskToUpdateId?.newComment;

    console.log(
      '🚀 ~ file: users-service.service.ts ~ line 144 ~ UsersServiceService ~ addCommentToTask ~ recipients',
      recipients,
    );
    this.taskUpdated.next('updateOk');
    this.http
      .put(
        this.url + 'addcommenttotask/' + userId,
        {
          taskToUpdateId: taskToUpdateId,
          comment: newComment,
          recipients: recipients,
        },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: users-service.service.ts ~ line 59 ~ UsersServiceService ~ .subscribe ~ response',
          response,
        );
      });
  }
  removeCommentFromTask(userId, taskToUpdateId, commentToRemoveId) {
    delete taskToUpdateId?.newComment;

    this.taskUpdated.next('updateOk');
    this.http
      .put(
        this.url + 'removecommentfromtask/' + userId,
        {
          taskToUpdateId: taskToUpdateId,
          commentToRemoveId: commentToRemoveId,
        },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: users-service.service.ts ~ line 59 ~ UsersServiceService ~ .subscribe ~ response',
          response,
        );
      });
  }
  setWorkersTasks(userId, tasks) {
    let tasksToSave = this.helpers.clone(tasks);
    tasksToSave.forEach((task) => {
      delete task.status;
      delete task.dontDisplay;
    });
    this.taskUpdated.next('updateOk');
    this.http
      .put(
        this.url + 'setworkerstasks/' + userId,
        { tasks: tasksToSave },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: users-service.service.ts ~ line 59 ~ UsersServiceService ~ .subscribe ~ response',
          response,
        );
      });
  }
  removeWorkersTask(userId, taskToRemoveId) {
    this.taskUpdated.next('updateOk');
    this.http
      .put(
        this.url + 'removeworkerstask/' + userId,
        { taskToRemoveId: taskToRemoveId },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: users-service.service.ts ~ line 59 ~ UsersServiceService ~ .subscribe ~ response',
          response,
        );
      });
  }
  updateWorkingHours(userId: string, month, hours) {
    this.http
      .put(
        this.url + 'updateworkinghours/' + userId,
        { month: month, hours: hours },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log(response);
      });
  }
  getWorkingHours(userId: string) {
    this.http
      .get(this.url + 'getworkinghours/' + userId, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        console.log(response);
        this.workersHoursUpdated.next(response);
      });
  }
  updateAvailability(userId: string, availability) {
    this.http
      .put(
        this.url + 'updateavailability/' + userId,
        { availability: availability },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log(response);
      });
  }
  getAvailability(userId: string) {
    this.http
      .get(this.url + 'getavailability/' + userId, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        console.log(response);
        this.workersAvailabilityUpdated.next(response);
      });
  }
  removeWorkingHours(userId: string) {
    this.http
      .put(
        this.url + 'removeworkinghours/' + userId,
        { userData: { workingHours: [] } },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log(response);
      });
  }
  findUsers(usersIds) {
    console.log('usersIds: ', usersIds);
    this.http
      .post(this.url + 'find-users', usersIds, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        console.log('response: ', response);
        let usersArray = Object.keys(response).map((i) => response[i]);
        let users = [];
        usersArray.forEach((user) => {
          if (user[0]?._id) {
            users.push(user[0]);
          }
        });

        this.foundUsersUpdated.next(users);
      });
  }

  getUsersSets(id) {
    this.http
      .get(this.url + 'getuserssets/' + id, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response: any) => {
        console.log('response: ', response);
        this.usersSetsList.next(response);
      });
  }
  getUsersProgress(usersIds) {
    this.http
      .post(this.url + 'get-users-progress', usersIds, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        console.log('response: ', response);
        let usersArray = Object.keys(response).map((i) => response[i]);
        let users = [];
        usersArray.forEach((user) => {
          if (user) {
            users.push(user);
          }
        });

        this.usersProgressUpdated.next(users);
      });
  }
  findUsersByRole(role) {
    this.http
      .post(
        this.url + 'find-users-by-role',
        { role: role },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        let usersArray = Object.keys(response).map((i) => response[i]);

        this.foundUsersUpdated.next(usersArray);
      });
  }
  findUsersByName(name): any {
    this.http
      .post(
        this.url + 'find-users-by-name',
        { name: name },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        let usersArray = Object.keys(response).map((i) => response[i]);
        console.log(
          '🚀 ~ file: users-service.service.ts ~ line 115 ~ UsersServiceService ~ findUsersByName ~ usersArray',
          usersArray,
        );

        this.foundUsersUpdated.next(usersArray);
        // return usersArray;
      });
  }
  resetWeeklyProgress(userId) {
    this.http
      .put(this.url + 'reset-weekly-progress/' + userId, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: users-service.service.ts ~ line 116 ~ UsersServiceService ~ resetWeeklyProgress ~ response',
          response,
        );
      }),
      (error) => {
        // this.errorLoggingService.sendLogToElastic("debug", {
        //   message: "error while resetting stats",
        //   error: error,
        // });
      };
  }
  updateStudentsAccountsIds(customerAppId, oldStudentAppId, newStudentAppId) {
    this.http
      .put(
        this.url + 'updatestudentsaccountsids/' + customerAppId,
        { oldStudentAppId: oldStudentAppId, newStudentAppId: newStudentAppId },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: users-service.service.ts ~ line 134 ~ UsersServiceService ~ updateStudentsAccountsIds ~ response',
          response,
        );
      });
  }
  addNewStudentsAccountsIds(userAppId, newStudentAppId) {
    this.http
      .put(
        this.url + 'addnewstudentsaccountsids/' + userAppId,
        { newStudentAppId: newStudentAppId },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: users-service.service.ts ~ line 134 ~ UsersServiceService ~ updateStudentsAccountsIds ~ response',
          response,
        );
      });
  }
  addNewStudentIdToUser(studentId, userId) {
    this.http
      .put(
        this.url + 'addstudent/' + userId,
        { studentId: studentId },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: users-service.service.ts ~ line 134 ~ UsersServiceService ~ updateStudentsAccountsIds ~ response',
          response,
        );
      });
  }
  RemoveStudentIdFromUser(studentId, userId) {
    this.http
      .put(
        this.url + 'removestudent/' + userId,
        { studentId: studentId },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: users-service.service.ts ~ line 134 ~ UsersServiceService ~ updateStudentsAccountsIds ~ response',
          response,
        );
      });
  }
  setUsersPhone(userAppId, phone) {
    this.http
      .put(
        this.url + 'setusersphone/' + userAppId,
        { phone: phone },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: users-service.service.ts ~ line 134 ~ UsersServiceService ~ updateStudentsAccountsIds ~ response',
          response,
        );
      });
  }
  setUsersProp(userAppId: string, prop: string, value: any) {
    console.log("🚀 ~ UsersServiceService ~ setUsersProp ~ value:", value)
    console.log("🚀 ~ UsersServiceService ~ setUsersProp ~ prop:", prop)
    this.http
      .put(
        `${this.url}setusersprop/${userAppId}`,
        { prop: prop, value: value },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log(`${prop} updated`, response);
      });
  }
  fetchUsers(pageIndex: number, pageSize: number) {
    // Calculate the start and end indices for the current page
    // const startIndex = pageIndex * pageSize;
    // const endIndex = startIndex + pageSize;
    // this.http
    //   .get(this.url + "getlist", {
    //     headers: { Authorization: `Bearer ${this.authService.getToken()}` },
    //   })
    //   .subscribe((response) => {
    //     let usersArray = Object.keys(response).map((i) => response[i]);
    //     usersArray = usersArray.map((incomingUser) => {
    //       return {
    //         id: incomingUser._id,
    //         name: incomingUser.name,
    //         email: incomingUser.email,
    //         role: incomingUser.role,
    //         status: incomingUser.status,
    //         userData: incomingUser.userData,
    //       };
    //     });
    //     // Only return the data for the current page
    //     let slicedUsers = usersArray.slice(startIndex, endIndex);
    //     this.usersUpdated.next(slicedUsers);
    //   });
  }
  getWorkersList() {
    this.http
      .get(this.url + 'getworkerslist', {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        let usersArray = Object.keys(response).map((i) => response[i]);

        usersArray = usersArray.map((incomingUser) => {
          return {
            id: incomingUser._id,
            name: incomingUser.name,
            email: incomingUser.email,
            role: incomingUser.role,
            status: incomingUser.status,

            userData: incomingUser.userData,
          };
        });

        this.workersUpdated.next(usersArray);
      });
  }
  getUsers() {
    this.usersUpdated.next(this.users);
  }
  filterUsers(name) {
    // const lowerText = text.toLowerCase();
    // const filteredUsers = this.users.filter((e) =>
    //   e.name.toLowerCase().includes(lowerText)
    // );
    if (name.length > 2) {
      this.http
        .post(
          this.url + 'find-users-by-name-includes',
          { name: name },
          {
            headers: { Authorization: `Bearer ${this.authService.getToken()}` },
          },
        )
        .subscribe((response: any) => {
          console.log(
            '🚀 ~ file: users-service.service.ts:517 ~ UsersServiceService ~ .subscribe ~ response:',
            response,
          );
          let usersArray = response[0].users || [];
          console.log(
            '🚀 ~ file: users-service.service.ts:531 ~ UsersServiceService ~ .subscribe ~ usersArray:',
            usersArray,
          );

          usersArray = usersArray.map((incomingUser) => {
            return {
              id: incomingUser._id,
              name: incomingUser.name,
              email: incomingUser.email,
              role: incomingUser.role,
              status: incomingUser.status,
              userData: incomingUser.userData,
            };
          });

          this.usersUpdated.next(usersArray);
        });
    }
    if (name.length === 0) {
      this.usersUpdated.next([]);
    }
  }

  removeUser(userId) {
    this.http
      .delete(this.url + userId, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe(() => {
        const updatedUsers = this.users.filter((user) => user.id !== userId);
        this.users = updatedUsers;
        this.usersUpdated.next([...this.users]);
      });
  }

  updateUser(user) {
    this.http
      .put(this.url + user.id, user, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        const oldUserIndex = this.users.findIndex((e) => e.id === user.id);
        // const updatedUsers = this.users.filter(user =>user.id !== userId);
        // this.users = updatedUsers;
        // this.usersUpdated.next([...this.users])
      });
  }
  updateUsersProperty(
    userId,
    propertyData: { propertyName: string; propertyValue: number },
  ) {
    this.http
      .put(this.url + 'update-users-property/' + userId, propertyData, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        console.log('response: ', response);
        // You can add any other logic you might need here
        // such as updating local data or emitting an event
      });
  }
  updateUsersAttendance(attendance) {
    this.http
      .put(this.url + 'updateusersattendance/' + attendance.appId, attendance, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        console.log('response: ', response);

        // const updatedUsers = this.users.filter(user =>user.id !== userId);
        // this.users = updatedUsers;
        // this.usersUpdated.next([...this.users])
      });
  }
  updateUsersGdpr(customerId, studentId, gdpr) {
    this.http
      .put(
        this.url + 'updateusersgdpr/' + customerId,
        { studentId: studentId, gdpr: gdpr },
        {
          headers: { Authorization: `Bearer ${this.authService.getToken()}` },
        },
      )
      .subscribe((response) => {
        console.log('response: ', response);

        // const updatedUsers = this.users.filter(user =>user.id !== userId);
        // this.users = updatedUsers;
        // this.usersUpdated.next([...this.users])
      });
  }
  getUsersSetStats(userId, setId) {
    console.log('setId: ', setId);
    this.http
      .put(
        this.url + 'getuserssetstats/' + userId,
        { setId: setId },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log('response: ', response);

        // const updatedUsers = this.users.filter(user =>user.id !== userId);
        // this.users = updatedUsers;
        // this.usersUpdated.next([...this.users])
      });
  }
  addGroupToUser(userId, groupId) {
    this.http
      .put(
        this.url + 'addgrouptouser/' + userId,
        { groupId: groupId },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => { });
  }
  removeGroupFromUser(userId, groupId) {
    this.http
      .put(
        this.url + 'removegroupfromuser/' + userId,
        { groupId: groupId },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => { });
  }
  addSetToUser(userId, set) {
    console.log('adding set to user...');
    set.stats = null;
    this.http
      .put(
        this.url + 'addsettouser/' + userId,
        { set: set },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => { });
  }
  addusersCollectableItems(userId, collectableItem) {
    this.http
      .put(
        this.url + 'adduserscollectableitems/' + userId,
        { collectableItem: collectableItem },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => { });
  }
  removeSetFromUser(userId, set) {
    this.http
      .put(
        this.url + 'removesetfromuser/' + userId,
        { set: set },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => { });
  }
  updateCustomerAccount(
    customerId,
    studentToAdd?,
    studentAccountIdToAdd?,
    alsoStudent?,
  ) {
    this.http
      .put(
        this.url + 'updatecustomeraccount/' + customerId,
        {
          alsoStudent: alsoStudent,
          studentToAdd: studentToAdd,
          studentsAccountsIdToAdd: studentAccountIdToAdd,
        },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => { });
  }
  updateCustomerStudent(customerId, alsoStudent?) {
    this.http
      .put(
        this.url + 'updatecustomerstudent/' + customerId,
        { alsoStudent: alsoStudent },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => { });
  }

  taskUpdatedListener() {
    return this.taskUpdated.asObservable();
  }
  userUpdatedListener() {
    return this.usersUpdated.asObservable();
  }
  workersUpdatedListener() {
    return this.workersUpdated.asObservable();
  }
  userSetsListListener() {
    return this.usersSetsList.asObservable();
  }
  usersProgressUpdatedListener() {
    return this.usersProgressUpdated.asObservable();
  }
  foundUserUpdatedListener() {
    return this.foundUsersUpdated.asObservable();
  }
  workersHoursUpdatedListener() {
    return this.workersHoursUpdated.asObservable();
  }
  avalibilityUpdatedListener() {
    return this.workersAvailabilityUpdated.asObservable();
  }
}
