import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import {
  PictureService
} from '../../../shared/services/pictures.service';
import {
  Subscription,
  Observable,
  Observer
} from 'rxjs';
// import {
//   Ng2ImgMaxService
// } from 'ng2-img-max';
import {
  DomSanitizer
} from '@angular/platform-browser';
import {
  ImageCroppedEvent
} from 'ngx-image-cropper';

@Component({
  selector: 'app-add-picture',
  templateUrl: './add-picture.component.html',
  styleUrls: ['./add-picture.component.css']
})

export class AddPictureComponent implements OnInit {
 controls = new EventEmitter()
@Input() copiedData
  tags = []
  imageChangedEvent: any = '';
  croppedImage: any = '';
  added
  imageForm: FormGroup
  tagForm: FormGroup
  imagePrv
  imageChanged
  image
  tag
  levelpicturesSub
  Pictures
  level
  foundPictures
  editMode = false
  pictureToEdit
  tagToFind
  levelToFind
  tagToAdd
  showSearch = false
  resizedImage
  bigImage
  smallImage
  iconImage
  bigUrl
  smallUrl
  iconUrl
  addspinner = false
  base64TrimmedURL
  generatedImage
  base64DefaultURL
  fileToupload
  imgLoaded = false
  imageTaken = false
  tagsError = false
  levelError = false


  // private picturesSub: Subscription
  // private foundPicturesSub: Subscription

  constructor(public sanitizer: DomSanitizer, private pictureService: PictureService, 
    private cdr: ChangeDetectorRef
    // private ng2ImgMax: Ng2ImgMaxService,
    ) {
    // this.pictureService.getLatestPictures()


  }

  ngOnInit() {
    // this.pictureService.getLatestPictures()
    // this.picturesSub = this.pictureService.pictureUpdateListener().subscribe(pictures => {
    //   console.log(pictures)
    //   this.Pictures = pictures
    // })
    // this.foundPicturesSub = this.pictureService.foundPicturesUpdateListener().subscribe(foundPictures => {
    //   console.log(foundPictures)

    //   this.foundPictures = foundPictures
    // })
    // this.tagToFind = ''
    // this.findPictures()
    this.imageForm = new FormGroup({
      'image': new FormControl(null, {
        validators: [Validators.required]
      })
    })
    // this.tagForm = new FormGroup({
    //   'tag': new FormControl(null, {
    //     validators: [Validators.required]
    //   }),
    // })
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.imgLoaded = true
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.getImage(this.croppedImage)
    console.log('this.croppedImage: ', this.croppedImage);
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  onPasteClicked(){
console.log(this.copiedData);
this.tags = this.copiedData.tags
this.level = this.copiedData.level
  }
  dataURItoBlob(dataURI: string): Observable < Blob > {
    return Observable.create((observer: Observer < Blob > ) => {
      const byteString: string = window.atob(dataURI);
      const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array: Uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], {
        type: "image/jpeg"
      });
      observer.next(blob);
      observer.complete();
    });
  }
  createBlobImageFileAndShow(): void {
    console.log('Creating blob from:', this.base64TrimmedURL?.substring(0, 50) + '...');
    this.dataURItoBlob(this.base64TrimmedURL).subscribe((blob: Blob) => {
      const imageBlob: Blob = blob;
      const imageName = Date.now().toString();
      const imageFile: File = new File([imageBlob], imageName, {
        type: "image/jpeg"
      });
      console.log('Created image file:', imageFile);
      this.onImagePicked(imageFile);
    });
  }
  getImage(imageUrl: string) {
    console.log('getImage called with:', imageUrl);
    if (imageUrl.startsWith('data:image')) {
      console.log('Handling base64 image');
      this.imgLoaded = true;
      this.imagePrv = this.sanitizer.bypassSecurityTrustUrl(imageUrl);
      this.base64TrimmedURL = imageUrl.replace(/^data:image\/(png|jpg|jpeg);base64,/, '');
      console.log('Image preview set:', this.imagePrv);
      this.createBlobImageFileAndShow();
      this.addspinner = true;
      this.controls.emit(this.addspinner);
      this.cdr.detectChanges();
    } else {
      console.log('Handling URL image');
      this.getBase64ImageFromURL(imageUrl).subscribe((base64Data: string) => {
        this.imgLoaded = true;
        this.base64TrimmedURL = base64Data;
        this.createBlobImageFileAndShow();
        this.addspinner = true;
        this.controls.emit(this.addspinner);
        this.cdr.detectChanges();
      });
    }
  }
  getBase64ImageFromURL(url: string): Observable < string > {
    return Observable.create((observer: Observer < string > ) => {
      // create an image object
      let img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = err => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }
  getBase64Image(img: HTMLImageElement): string {
    // We create a HTML canvas object that will create a 2d image
    var canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    // This will draw image
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    let dataURL: string = canvas.toDataURL("image/jpg");
    this.base64DefaultURL = dataURL;
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }



  onImagePicked(file) {
    this.imageChanged = true;
    this.addspinner = true;
    let image = file;
    
    // Big image
    this.resizeImage(image, 3508, 2480)
      .then((blob:any) => {
        this.bigImage = new File([blob], 'big-' + file.name, { type: file.type });
        // Small Image
        return this.resizeImage(image, 900, 636);
      })
      .then((blob:any) => {
        this.smallImage = new File([blob], 'small-' + file.name, { type: file.type });
        // Icon Image
        return this.resizeImage(image, 240, 160);
      })
      .then((blob:any) => {
        this.iconImage = new File([blob], 'icon-' + file.name, { type: file.type });
        this.addspinner = false;
        this.controls.emit(this.addspinner);
        this.imageTaken = true;
      })
      .catch(err => {
        console.log('Error resizing images', err);
      });
}

resizeImage(imageFile, maxWidth, maxHeight) {
  return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(imageFile);
      img.onload = () => {
          const aspectRatio = img.width / img.height;
          let newWidth = maxWidth;
          let newHeight = maxHeight;

          if (img.width < img.height) {
              newWidth = maxHeight * aspectRatio;
          } else {
              newHeight = maxWidth / aspectRatio;
          }

          const canvas = document.createElement('canvas');
          canvas.width = newWidth;
          canvas.height = newHeight;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, newWidth, newHeight);

          canvas.toBlob((blob) => {
              if (!blob) {
                  reject(new Error('Image resize error'));
              } else {
                  // Resolve resized image
                  resolve(blob);
              }
              // Clean-up memory
              URL.revokeObjectURL(img.src);
          }, imageFile.type || 'image/png');
      };
      img.onerror = () => {
          reject(new Error('There was an error loading the image.'));
      };
  });
}

  // onImagePicked(file) {
  //   this.imageChanged = true
  //   console.log(event);
  //   this.addspinner = true
  //   // let image = (event.target as HTMLInputElement).files[0];
  //   let image = file



  //   this.ng2ImgMax.resizeImage(image, 3508, 2480).subscribe(
  //     result => {

  //       const name = 'big-' + result.name
  //       this.bigImage = new File([result], name, {
  //         type: image.type
  //       });
  //       console.log(this.bigImage)


  //     },
  //     error => {
  //       console.log('😢 Oh no!', error);
  //     }
  //   );
  //   this.ng2ImgMax.resizeImage(image, 900, 636).subscribe(
  //     result => {

  //       const name = 'small-' + result.name
  //       this.smallImage = new File([result], name, {
  //         type: image.type
  //       });
  //       console.log(this.smallImage)


  //     },
  //     error => {
  //       console.log('😢 Oh no!', error);
  //     }
  //   );
  //   this.ng2ImgMax.resizeImage(image, 240, 160).subscribe(
  //     result => {

  //       const name = 'icon-' + result.name
  //       this.iconImage = new File([result], name, {
  //         type: image.type
  //       });
  //       console.log(this.iconImage)
  //       this.addspinner = false
  //       this.controls.emit(this.addspinner)
  //       // this.getImagePreview(this.iconImage);
  //       this.imageTaken = true
  //     },
  //     error => {
  //       console.log('😢 Oh no!', error);
  //     }
  //   );

  // }

  getImagePreview(file: File) {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.imagePrv = reader.result;
    };





  }
  addTag() {
    this.tags.unshift(this.tagToAdd.trim())
    this.tagToAdd = ''
    this.tagsError = false
  }
removeTag(t){
  this.tags.splice(t,1)
}




  onAddClicked() {
if(this.level === undefined){
  this.levelError = true
  return null
}
if(this.tags.length === 0){
  this.tagsError = true
  return null
}
console.log(this.level);
console.log(this.tags);
    const firstAdded = new Date()
    const pictureData = {
      tags: this.tags,
      added: firstAdded.setHours(firstAdded.getHours() + 2),

      level: this.level
    }
    this.pictureService.addPicture(pictureData, this.bigImage, this.smallImage, this.iconImage, this.imageChanged)
    // setTimeout(function(){ this.pictureService.getAllPictures() }, 1000);
    this.tags = []
    this.imagePrv = undefined
  this.imageChanged = undefined
  this.image = undefined
  this.tag = undefined
  this.levelpicturesSub = undefined
  this.Pictures = undefined
  this.level = undefined
  this.foundPictures = undefined
  this.editMode = undefined
  this.pictureToEdit = undefined
  this.tagToFind = undefined
  this.levelToFind = undefined
  this.tagToAdd = undefined
  this.showSearch = undefined
  this.resizedImage = undefined
  this.bigImage = undefined
  this.smallImage = undefined
  this.iconImage = undefined
  this.bigUrl = undefined
  this.smallUrl = undefined
  this.iconUrl = undefined
  this.addspinner = undefined
  this.base64TrimmedURL = undefined
  this.generatedImage = undefined
  this.base64DefaultURL = undefined
  this.croppedImage = undefined
  this.fileToupload = undefined
  this.imgLoaded = false

  }

  editPicture(picture) {
    this.pictureToEdit = picture
    this.imagePrv = picture.iconUrl
    this.tags = picture.tags
    this.level = picture.level
    this.editMode = true
    // this.bigUrl = picture.bigUrl
    // this.smallUrl = picture.smallUrl
    // this.iconUrl = picture.iconUrl

  }

  onUpdateClicked() {

    const pictureData = {
      tags: this.tags,

      level: this.level,
      //    bigUrl: this.bigUrl ,
      // smallUrl: this.smallUrl ,
      // iconUrl:this.iconUrl
    }
    this.pictureService.updatePicture(this.pictureToEdit.id, pictureData)
    this.imagePrv = ''
    this.tags = []
    this.level = ''
    this.editMode = false
  }
  removePicture(picture, p) {
    this.pictureService.deletePicture(picture.id)
    p++
    this.foundPictures.splice(p, 1)
  }



  findPictures() {
    this.pictureService.findPictures(this.tagToFind, this.levelToFind)
  }
}
