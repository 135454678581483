<div class="ai-image-generator-container" role="main">
  <div class="generated-image" *ngIf="generatedImage">
    <img
      [src]="generatedImage"
      alt="AI generated image from your description"
      (error)="
        lastMessage = 'Error loading image. The response might be too large.'
      "
      loading="lazy"
    />
  </div>

  <div
    class="generated-image placeholder"
    *ngIf="!generatedImage"
    role="img"
    aria-label="Image placeholder"
  >
    <div class="placeholder-content">
      <mat-icon aria-hidden="true">image</mat-icon>
      <p>Enter a description and click Generate to create an image</p>
    </div>
  </div>

  <div
    class="status-message"
    *ngIf="lastMessage"
    role="status"
    aria-live="polite"
  >
    {{ lastMessage }}
  </div>

  <form [formGroup]="imageForm" (ngSubmit)="onSubmit()" class="input-area">
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Image Description</mat-label>
      <textarea
        matInput
        formControlName="prompt"
        placeholder="Describe what you want to generate..."
        rows="3"
        [attr.aria-label]="'Image description input'"
        cdkTextareaAutosize
        cdkAutosizeMinRows="3"
        cdkAutosizeMaxRows="6"
      ></textarea>
      <mat-error *ngIf="imageForm.get('prompt')?.hasError('required')">
        Description is required
      </mat-error>
      <mat-error *ngIf="imageForm.get('prompt')?.hasError('minlength')">
        Description must be at least 3 characters
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Style Preset</mat-label>
      <mat-select formControlName="stylePreset">
        <mat-option [value]="null">No preset</mat-option>
        <mat-option
          *ngFor="let preset of stylePresets"
          [value]="preset.id"
          [matTooltip]="preset.description"
        >
          {{ preset.name }}
        </mat-option>
      </mat-select>
      <mat-hint>Select a style preset to enhance your image</mat-hint>
    </mat-form-field>

    <div class="button-container">
      <button
        mat-stroked-button
        type="button"
        (click)="dialogRef.close()"
        aria-label="Cancel and close"
      >
        <mat-icon>close</mat-icon>
        <span>Cancel</span>
      </button>
      <button
        mat-raised-button
        type="button"
        color="warn"
        (click)="reset()"
        [disabled]="
          isGenerating ||
          (!generatedImage &&
            !imageForm.value.prompt &&
            !imageForm.value.stylePreset)
        "
      >
        <mat-icon>refresh</mat-icon>
        Reset
      </button>
      <button
        mat-stroked-button
        type="button"
        [disabled]="imageForm.invalid || isExtending"
        (click)="onExtendPrompt()"
        aria-label="Enhance description"
        matTooltip="Enhance the description with more details"
      >
        <mat-icon>auto_awesome</mat-icon>
        <span>{{ isExtending ? "Enhancing..." : "Enhance" }}</span>
      </button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="imageForm.invalid || isGenerating"
        aria-label="Generate"
      >
        <mat-icon>auto_fix_high</mat-icon>
        <span>{{ isGenerating ? "Generating..." : "Generate" }}</span>
      </button>
      <button
        mat-raised-button
        color="accent"
        type="button"
        [disabled]="!generatedImage"
        (click)="acceptImage()"
        aria-label="Accept image"
      >
        <mat-icon>check</mat-icon>
        <span>Accept</span>
      </button>
    </div>
  </form>
</div>
