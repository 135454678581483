import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WorkersAvatarsService {
  workersWithAvatars = [
    {
      name: 'Małgorzata Porazińska',
      avatar: '/assets/workers-avatars/malgosia.svg',
      id: '5f5e1157dc6a9b01e5d84cf7',
      priority: 0,
    },
    {
      name: 'Krzysztof Jacek',
      avatar: '/assets/workers-avatars/krzysztof.svg',
      teacherId: 1622052711530,
      colorDark: '#00695c',
      colorLight: '#90caf9',
      id: '5f5b359adc6a9b01e5d84cd4',
      priority: 3,
    },
    {
      name: 'Agnieszka Jacek',
      avatar: '/assets/workers-avatars/agnieszka.svg',
      teacherId: 1612792193684,
      colorDark: '#9c27b0',
      colorLight: '#ce93d8',
      id: '5f1c298d09cf8014776c8fd2',
      priority: 3,
    },
    {
      name: 'Magdalena Brodzińska',
      avatar: '/assets/workers-avatars/magda.svg',
      teacherId: 8732458273494,
      colorDark: '#00bcd4',
      colorLight: '#80deea',
      id: '602248d4d33b8a56b8856447',
      priority: -1,
    },

    {
      name: 'Karolina Frasz',
      avatar: '/assets/workers-avatars/karolina_f.png',
      teacherId: 1673508763282,
      colorDark: '#e65100',
      colorLight: '#fb8c00',
      id: '63bfb788007c8730b50e86df',
      priority: 1,
    },
    {
      name: 'Nicole Hojnowska',
      avatar: '/assets/workers-avatars/nicole.svg',
      teacherId: 1696227720803,
      colorDark: '#aeb06b',
      colorLight: '#dde31b',
      id: '651a6189734b8d0cd5f358b3',
      priority: 1,
    },
    {
      name: 'Wiktoria Zakrzewska',
      avatar: '/assets/workers-avatars/wiktoria.svg',
      teacherId: 1706273590914,
      colorDark: '#009688',
      colorLight: '#009688',
      id: '65b3ab37b62dcf505d747e6a',
      priority: 1,
    },
    {
      name: 'Adrianna Butka',
      avatar: '/assets/workers-avatars/adrianna.svg',
      teacherId: 1706522613965,
      colorDark: '#f4511e',
      colorLight: '#8d6e63',
      id: "65b777f6b62dcf505d74db8b",
      priority: 1,
    },
    // {
    //   name: 'Olga Gosk',
    //   avatar: '/assets/workers-avatars/Olga.svg',
    //   teacherId: 1666205215064,
    //   colorDark: '#f4511e',
    //   colorLight: '#8d6e63',
    //   id: '6350461e99b4267d5af1e62e',
    //   priority: -1,
    // },
    {
      name: 'Weronika Oczki',
      avatar: '/assets/workers-avatars/weronika.svg',
      teacherId: 1667504609486,
      colorDark: '#1976d2',
      colorLight: '#1976d2',
      id: '636419e15158257d522206e4',
      priority: 1,
    },
    // {
    //   name: 'Aleksandra Domian',
    //   avatar: '/assets/workers-avatars/Olga.svg',
    //   teacherId: 1699526060081,
    //   colorDark: '#32a852',
    //   colorLight: '#54bf71',
    //   id: '654cb5acee8a3726c25dcdaa',
    //   priority: -1,
    // },
    {
      name: 'Joanna Staniszewska',
      avatar: '/assets/workers-avatars/Asia.svg',
      teacherId: 1667504773110,
      colorDark: '#006064',
      colorLight: '#00e5ff',
      id: '63641a845158257d522206ee',
      priority: 1,
    },
    {
      name: 'Anna Pokorska',
      avatar: '/assets/workers-avatars/Olga.svg',
      teacherId: 1725450388435,
      colorDark: '#f44336',
      colorLight: '#ff5722',
      id: '66d84894e99a9e20a277129f',
      priority: 1,
    },

    // {
    //   name: 'Julia Ziemka',
    //   avatar: '/assets/workers-avatars/Julia.svg',
    //   teacherId: 1666205172357,
    //   colorDark: '#f44336',
    //   colorLight: '#ff5722',
    //   id: '635045f399b4267d5af1e625',
    //   priority: 1,
    // },
    // {
    //   name: 'Ernest Essuman Mensah',
    //   avatar: '/assets/workers-avatars/ernest.svg',
    //   teacherId: 1612859661083,
    //   colorDark: '#fbc02d',
    //   id: '6022490c03667556b13d522f',
    //   colorLight: '#fff59d',

    //   priority: -1,
    // },
    {
      name: 'Zuzanna Baranowska',
      avatar: '/assets/workers-avatars/zuzanna.svg',

      priority: 0,
    },
    // {
    //   name: 'Monika Kowalewska',

    //   priority: -1,
    // },
    {
      name: 'Karolina Szewczyk',
      avatar: '/assets/workers-avatars/karolina.svg',
      id: '6314e267125a570e706bffcc',

      priority: 0,
    },
    {
      name: 'Katarzyna Sobieszczyk',
      avatar: '/assets/workers-avatars/katarzyna.svg',
      id: '640da3ce1e3c5e27dabc1379',

      priority: 3,
    },
  ];
  constructor() { }
  getAvatarURL(name) {
    const avatar = this.workersWithAvatars.find((user) => user.name == name)
      ?.avatar;
    if (avatar) {
      return avatar;
    }
  }
  getAvatarURLById(id) {
    const avatar = this.workersWithAvatars.find((user) => user.id == id)
      ?.avatar;
    if (avatar) {
      return avatar;
    }
  }
  getNameFromId(id) {
    const name = this.workersWithAvatars.find((user) => user.id == id)?.name;
    if (name) {
      return name;
    }
  }
}
