<div class="vertical-tabs">
  <nav class="tabs-nav">
    <button
      mat-stroked-button
      (click)="selectTab('lessons')"
      [class.active]="activeTab === 'lessons'"
    >
      Lekcje
    </button>
    <button
      (click)="selectTab('courses')"
      [class.active]="activeTab === 'courses'"
      mat-stroked-button
    >
      Szkolenia
    </button>
    <button
      (click)="selectTab('absences')"
      [class.active]="activeTab === 'absences'"
      mat-stroked-button
    >
      Nieobecności
    </button>
    <button
      (click)="selectTab('resignations')"
      [class.active]="activeTab === 'resignations'"
      mat-stroked-button
    >
      Rezygnacje
    </button>
  </nav>

  <div class="tab-content">
    <ng-container *ngIf="activeTab === 'lessons'">
      <app-lessons [teacher]="teacher"></app-lessons>
    </ng-container>
    <ng-container *ngIf="activeTab === 'courses'">
      <app-courses [teacher]="teacher"></app-courses>
    </ng-container>
    <ng-container *ngIf="activeTab === 'absences'">
      <app-teacher-absences [teacher]="teacher"></app-teacher-absences>
    </ng-container>
    <ng-container *ngIf="activeTab === 'resignations'">
      <app-students-resignations
        [teacher]="teacher"
      ></app-students-resignations>
    </ng-container>
  </div>
</div>
