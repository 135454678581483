<div
  *ngIf="users.length == 0"
  style="width: 100%; height: 80%"
  fxLayoutAlign="center center"
>
  <h2 style="color: #673ab7">Tutaj pojawią się znalezieni użytkownicy</h2>
</div>
<mat-card *ngIf="users.length > 0" class="m-3">
  <div class="table-container">
    <table
      [dataSource]="dataSource"
      class="mat-elevation-z2"
      mat-table
      matSort
      multiTemplateDataRows
      style="width: 100%"
    >
      <!-- - Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
      <tbody>
        <tr
          *matHeaderRowDef="displayedColumns; sticky: true"
          mat-header-row
        ></tr>
        <ng-container matColumnDef="name">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Name</th>
          <td *matCellDef="let element; let i = dataIndex" mat-cell>
            <span *ngIf="!editMode || i !== index">{{ element.name }}</span>
            <span *ngIf="editMode && i == index">
              <mat-form-field style="margin-bottom: -20px">
                <input [(ngModel)]="element.name" matInput />
              </mat-form-field>
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>E-mail</th>
          <td *matCellDef="let element; let i = dataIndex" mat-cell>
            <span *ngIf="!editMode || i !== index">{{ element.email }}</span>
            <span *ngIf="editMode && i == index">
              <mat-form-field style="margin-bottom: -20px">
                <input [(ngModel)]="element.email" matInput />
              </mat-form-field>
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="role">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Role</th>
          <td *matCellDef="let element; let i = dataIndex" mat-cell>
            <span *ngIf="!editMode || i !== index">{{ element.role }}</span>
            <span *ngIf="editMode && i == index">
              <mat-form-field style="margin-bottom: -20px">
                <mat-select [(value)]="element.role">
                  <mat-option *ngFor="let role of roles" [value]="role"
                    >{{ role }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Status</th>
          <td *matCellDef="let element; let i = dataIndex" mat-cell>
            <span *ngIf="!editMode || i !== index">{{ element.status }}</span>
            <span *ngIf="editMode && i == index">
              <mat-form-field style="margin-bottom: -20px">
                <mat-select [(value)]="element.status">
                  <mat-option *ngFor="let status of statuses" [value]="status"
                    >{{ status }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <div>
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Actions</th>
            <td *matCellDef="let element; let i = dataIndex" mat-cell>
              <mat-icon
                (click)="onEditUserClick(i, element)"
                *ngIf="
                  (!editMode || i !== index) &&
                  (userDetails.owner || userDetails.office)
                "
                class="md-24"
                style="cursor: pointer"
                >edit
              </mat-icon>
              <a>
                <span
                  matTooltip="Kopiuj id konta aplikacji"
                  (click)="copyStudentAppId(element)"
                  class="md-24 ml-2"
                  style="cursor: pointer"
                  class="material-symbols-outlined"
                  >settings_account_box
                </span>
              </a>
              <a>
                <mat-icon
                  (click)="onUserEditFinished(element)"
                  *ngIf="editMode && i == index"
                  class="md-24"
                  style="cursor: pointer"
                  >done
                </mat-icon>
              </a>
              <a>
                <mat-icon
                  matTooltip="Usuń użytkownika"
                  *ngIf="userDetails.owner"
                  (dblclick)="onRemoveUserClick(element)"
                  class="md-24"
                  class="ml-2"
                  color="warn"
                  style="cursor: pointer"
                  >delete
                </mat-icon>
              </a>
              <a>
                <mat-icon
                  (click)="element.isExpanded = !element.isExpanded"
                  *ngIf="!element.isExpanded"
                  class="md-24 ml-2"
                  style="cursor: pointer"
                  >expand_more
                </mat-icon>
              </a>
              <a>
                <mat-icon
                  (click)="element.isExpanded = !element.isExpanded"
                  *ngIf="element.isExpanded"
                  class="md-24 ml-2"
                  style="cursor: pointer"
                  >expand_less
                </mat-icon>
              </a>
            </td>
          </div>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
          <td
            *matCellDef="let element; let i = index"
            [attr.colspan]="displayedColumns.length"
            mat-cell
          >
            <div
              [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'"
              class="row student-element-detail"
            >
              <div mat-subheader>Szczegóły uzytkownika {{ element.name }}</div>
              <div
                mat-subheader
                *ngIf="element.userData?.tempPassword?.length > 0"
              >
                Temp Password:{{ element.userData?.tempPassword }}
              </div>
              <!-- <div mat-subheader>userId:{{element.userData?.id}}</div> -->
              <mat-form-field>
                <input
                  matInput
                  [(ngModel)]="newUserIdToAdd"
                  placeholder="add new student id to user"
                />
              </mat-form-field>
              <button (click)="addNewStudentId(i, element)" matButton>
                add new student id
              </button>
              <mat-form-field *ngIf="userDetails.owner">
                <input
                  matInput
                  [(ngModel)]="usersPhoneToSet"
                  placeholder="set users phone"
                />
              </mat-form-field>
              <button
                *ngIf="userDetails.owner"
                (click)="setUsersPhone(i, element)"
                matButton
              >
                set users phone
              </button>

              <button
                *ngIf="userDetails.owner"
                (click)="setAlsoStudent(element)"
                matButton
              >
                set also student
              </button>
              <button
                *ngIf="userDetails.owner"
                (click)="setUsersProp(element)"
                matButton
              >
                set users prop
              </button>
              <button (click)="generateCode(element)" matButton>
                generate code
              </button>
              <button (click)="addStudentToUser(element)" matButton>
                add student
              </button>
              <div mat-subheader>{{ element.userData?.data }}</div>
              <ul *ngFor="let item of element.userData?.array">
                <li>{{ item }}</li>
              </ul>
            </div>
          </td>
        </ng-container>
        <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->
        <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
        <tr
          *matRowDef="let element; columns: displayedColumns"
          [class.student-expanded-row]="element.isExpanded"
          class="student-element-row"
          mat-row
        ></tr>
        <tr
          *matRowDef="let row; columns: ['expandedDetail']"
          class="student-detail-row"
          mat-row
        ></tr>
      </tbody>
    </table>
  </div>
  <mat-paginator
    [pageSizeOptions]="[10, 100, 1000, 10000]"
    class="mat-elevation-z2"
    class="mat-paginator-sticky"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
