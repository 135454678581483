<div class="code-block">
  <div class="code-header">
    <span class="language-tag" *ngIf="language">{{ language }}</span>
    <button
      mat-icon-button
      class="copy-button"
      matTooltip="Copy code"
      (click)="$event.preventDefault(); $event.stopPropagation(); copyCode()"
    >
      <mat-icon>content_copy</mat-icon>
    </button>
  </div>
  <pre><code #codeElement></code></pre>
</div>
