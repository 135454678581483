import { Component, Input, OnInit } from '@angular/core';
import { Teacher } from '../../shared/interfaces/teacher.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsersServiceService } from '../../shared/services/users-service.service';

interface TeacherAbsence {
  startDate: Date;
  endDate: Date;
  reason: string;
  addedAt: Date;
}

@Component({
  selector: 'app-teacher-absences',
  templateUrl: './teacher-absences.component.html',
  styleUrls: ['./teacher-absences.component.css']
})
export class TeacherAbsencesComponent implements OnInit {
  @Input() teacher!: Teacher;
  absenceForm: FormGroup;
  absences: TeacherAbsence[] = [];

  constructor(
    private fb: FormBuilder,
    private usersService: UsersServiceService
  ) {
    this.absenceForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      reason: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    if (this.teacher?.id) {
      // Load existing absences from user data
      this.loadAbsences();
    }
  }

  private loadAbsences(): void {
    if (this.teacher.userData?.absences) {
      this.absences = this.teacher.userData.absences;
    }
  }

  addAbsence(): void {
    if (this.absenceForm.valid) {
      const newAbsence: TeacherAbsence = {
        ...this.absenceForm.value,
        addedAt: new Date()
      };
      this.absences = [...this.absences, newAbsence];
      this.saveAbsences();
      this.absenceForm.reset();
    }
  }

  removeAbsence(index: number): void {
    this.absences = this.absences.filter((_, i) => i !== index);
    this.saveAbsences();
  }

  private saveAbsences(): void {
    if (this.teacher?.id) {
      this.usersService.setUsersProp(this.teacher.id, 'absences', this.absences);
    }
  }
}
