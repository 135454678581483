<div class="chat-container d-flex flex-column">
  <div class="chat-header p-2">
    <div class="d-flex flex-row align-items-center mb-3">
      <img class="avatar-image" [src]="selectedChatbot.avatar" />
      <div class="welcome-text">
        <span class="raleway-font">
          Welcome {{ usersFirstName }}! {{ selectedChatbot.name }} here.
        </span>
        <span class="raleway-font mat-small">
          {{ selectedChatbot.description }}
        </span>
      </div>
    </div>
    <div
      class="d-flex align-items-center"
      [style.width]="isMobile ? '80vw' : null"
    >
      <label for="chatbot-select">Chatbot</label>
      <select
        id="chatbot-select"
        [(ngModel)]="selectedChatbot"
        (change)="selectChatbot($event)"
      >
        <option *ngFor="let chatbot of chatbots" [ngValue]="chatbot">
          {{ chatbot.name }}&#10;{{ chatbot.description }}
        </option>
      </select>
      <button title="Start a new conversation" (click)="startNewConversation()">
        <i class="material-icons">refresh</i>
      </button>
    </div>
  </div>
  <div class="chat-messages flex-grow-1" #chatMessages>
    <div
      *ngFor="let message of conversation"
      class="message"
      [ngClass]="{
        'user-message': message.role === 'user',
        'ai-message': message.role !== 'user'
      }"
    >
      <div class="message-content">
        <div class="d-flex flex-row justify-content-start align-items-center">
          <img
            class="avatar-image"
            [src]="
              message.role == 'user' ? usersAvatar : selectedChatbot.avatar
            "
            alt=""
          />
          <div class="d-flex flex-column ml-2">
            <strong
              >{{
                message.role === "user" ? usersFirstName : selectedChatbot.name
              }}:</strong
            >
          </div>
        </div>
        <hr />
        <div *ngFor="let part of message.splitContent">
          <div *ngIf="part.type === 'text'">
            <span
              [style.font-size]="isMobile ? '' : 'larger'"
              class="raleway-font"
              [innerHTML]="part.value"
            ></span>
          </div>
          <app-code-block
            *ngIf="part.type === 'code'"
            [content]="part.value"
          ></app-code-block>
          <div *ngIf="part.type === 'image'" class="image-container">
            <img [src]="part.value" [alt]="part.alt || 'Generated image'" />
            <button
              (click)="downloadImage(part.value, part.alt)"
              title="Download image"
            >
              <i class="material-icons">download</i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="chat-input mt-auto">
    <div class="message-input d-flex align-items-center">
      <textarea
        [(ngModel)]="message"
        [placeholder]="selectedChatbot.description"
        (keyup.enter)="sendMessage()"
        class="flex-grow-1"
      ></textarea>
      <button
        [disabled]="!selectedChatbot.hasVision"
        title="Upload a file"
        (click)="fileInput.click()"
      >
        <i class="material-icons">attach_file</i>
      </button>
      <button (click)="sendMessage()">
        <i class="material-icons">send</i>
      </button>
    </div>
    <input
      hidden
      type="file"
      #fileInput
      (change)="onFileSelected($event)"
      multiple
    />
  </div>
</div>
