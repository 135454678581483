import { Injectable } from '@angular/core';

export interface StylePreset {
    id: string;
    name: string;
    description: string;
    stylePrompt: string;
}

@Injectable({
    providedIn: 'root'
})
export class ImageStylePresetsService {
    private presets: StylePreset[] = [
        {
            id: 'realistic',
            name: 'Realistic Photography',
            description: 'Professional photography style with natural lighting and rich details',
            stylePrompt: 'ultra-realistic 8K photography, natural lighting, shallow depth of field, professional camera, shot on Canon EOS R5, sharp focus, detailed textures, perfect exposure, cinematic color grading'
        },
        {
            id: 'anime',
            name: 'Modern Anime',
            description: 'Contemporary Japanese anime style with vibrant colors',
            stylePrompt: 'high-quality anime artwork, Studio Ghibli inspired, crisp linework, cel shading, vibrant colors, dynamic lighting, detailed backgrounds, manga-style shading, modern anime aesthetic'
        },
        {
            id: 'watercolor',
            name: 'Watercolor Painting',
            description: 'Traditional watercolor art with soft edges and organic textures',
            stylePrompt: 'traditional watercolor painting, handmade paper texture, wet-on-wet technique, organic color bleeding, soft edges, delicate brushstrokes, natural pigments, artistic composition, subtle color gradients'
        },
        {
            id: 'digital-art',
            name: 'Digital Art',
            description: 'Modern digital illustration with clean style',
            stylePrompt: 'professional digital illustration, clean vectorized style, bold colors, sharp edges, modern design principles, perfect lighting, high contrast, detailed shading, professional composition'
        },
        {
            id: 'oil-painting',
            name: 'Oil Painting',
            description: 'Classical oil painting style with rich textures',
            stylePrompt: 'classical oil painting, rich impasto technique, fine art, textured canvas, masterful brushwork, traditional painting style, dramatic lighting, Renaissance-inspired, museum quality'
        },
        {
            id: 'cyberpunk',
            name: 'Cyberpunk',
            description: 'Futuristic cyberpunk aesthetic with neon colors',
            stylePrompt: 'cyberpunk style, neon lighting, dark urban environment, high-tech elements, rain-slicked streets, holographic effects, dystopian atmosphere, futuristic technology, blade runner inspired'
        },
        {
            id: 'fantasy',
            name: 'Fantasy Art',
            description: 'Epic fantasy art style with magical elements',
            stylePrompt: 'epic fantasy art, magical atmosphere, detailed worldbuilding, dramatic lighting, mythical elements, rich colors, atmospheric effects, inspired by Frank Frazetta and Magic: The Gathering art'
        },
        {
            id: 'minimalist',
            name: 'Minimalist',
            description: 'Clean minimalist style with simple shapes',
            stylePrompt: 'minimalist design, clean lines, simple shapes, limited color palette, negative space, geometric composition, modern aesthetics, subtle gradients, elegant simplicity'
        },
        {
            id: 'retro',
            name: 'Retro 80s',
            description: 'Vintage 80s aesthetic with synthwave elements',
            stylePrompt: 'retro 80s style, synthwave aesthetic, VHS effects, neon colors, chrome elements, sunset gradients, grid patterns, vintage color palette, retro-futuristic design'
        },
        {
            id: 'pixel-art',
            name: 'Pixel Art',
            description: 'Classic pixel art style with limited palette',
            stylePrompt: 'detailed pixel art, 16-bit style, limited color palette, clean pixel-perfect edges, dithering effects, retro gaming aesthetic, isometric perspective, nostalgic video game style'
        },
        {
            id: 'concept-art',
            name: 'Concept Art',
            description: 'Professional concept art style for games/films',
            stylePrompt: 'professional concept art, cinematic composition, detailed environment design, atmospheric lighting, industry standard quality, textured brushwork, environmental storytelling, matte painting style'
        },
        {
            id: 'pop-art',
            name: 'Pop Art',
            description: 'Bold pop art style inspired by Andy Warhol',
            stylePrompt: 'pop art style, bold colors, halftone dots, comic book effects, Andy Warhol inspired, high contrast, simplified shapes, screen printing aesthetic, vibrant palette'
        },
        {
            id: 'gothic',
            name: 'Gothic Dark',
            description: 'Dark gothic style with dramatic lighting',
            stylePrompt: 'gothic dark art, dramatic chiaroscuro lighting, baroque influences, dark atmosphere, intricate details, medieval architecture, moody shadows, ornate decorative elements, dramatic contrast'
        },
        {
            id: 'steampunk',
            name: 'Steampunk',
            description: 'Victorian-era steampunk aesthetic',
            stylePrompt: 'steampunk aesthetic, brass and copper tones, Victorian era styling, mechanical elements, steam-powered machinery, intricate gears, vintage industrial design, ornate decorative details'
        },
        {
            id: 'impressionist',
            name: 'Impressionist',
            description: 'Dreamy impressionist painting style with loose brushwork',
            stylePrompt: 'impressionist painting style, loose brushwork, natural light effects, vibrant color palette, outdoor scenes, emphasis on light and movement, inspired by Monet and Renoir, visible brushstrokes, atmospheric perspective, plein air painting aesthetic'
        },
        {
            id: 'film-noir',
            name: 'Film Noir',
            description: 'Classic black and white film noir style with dramatic shadows',
            stylePrompt: 'film noir style, high contrast black and white, dramatic shadows, moody lighting, cinematic composition, 1940s aesthetic, deep shadows, stark highlights, dramatic camera angles, mysterious atmosphere, venetian blind shadows'
        },
        {
            id: 'art-nouveau',
            name: 'Art Nouveau',
            description: 'Elegant Art Nouveau style with organic flowing lines',
            stylePrompt: 'art nouveau style, flowing organic lines, decorative elements, natural forms, elegant curves, Alphonse Mucha inspired, botanical motifs, ornate details, gilded elements, symmetrical composition, pastel color palette'
        },
        {
            id: 'isometric',
            name: 'Isometric Design',
            description: 'Clean isometric technical illustration style',
            stylePrompt: 'isometric technical illustration, precise geometric perspective, clean lines, architectural detail, technical drawing style, professional visualization, subtle shadows, organized composition, perfect 30-degree angles, detailed technical elements'
        },
        {
            id: 'ukiyo-e',
            name: 'Ukiyo-e',
            description: 'Traditional Japanese woodblock print style',
            stylePrompt: 'ukiyo-e style, traditional Japanese woodblock print, Hokusai inspired, bold outlines, flat color areas, traditional Japanese aesthetic, detailed patterns, wave patterns, mount fuji style backgrounds, careful composition, natural elements'
        },
        {
            id: 'surrealist',
            name: 'Surrealist',
            description: 'Dream-like surrealist art style',
            stylePrompt: 'surrealist art style, Salvador Dali inspired, dreamlike atmosphere, impossible physics, metaphysical imagery, hyper-detailed elements, mysterious lighting, symbolic objects, subconscious imagery, distorted perspective, magical realism'
        },
        {
            id: 'low-poly',
            name: 'Low Poly',
            description: 'Modern low polygon 3D art style',
            stylePrompt: 'low poly 3D art, geometric shapes, clean edges, flat shading, minimalist design, triangular faces, modern aesthetic, subtle gradients, sharp angles, video game art style, simplified forms, bold color choices'
        },
        {
            id: 'baroque',
            name: 'Baroque',
            description: 'Rich and dramatic baroque painting style',
            stylePrompt: 'baroque painting style, dramatic chiaroscuro, rich colors, ornate details, Caravaggio inspired, theatrical lighting, religious imagery, emotional intensity, classical composition, rich fabric textures, golden hour lighting'
        },
        {
            id: 'stained-glass',
            name: 'Stained Glass',
            description: 'Medieval stained glass window art style',
            stylePrompt: 'stained glass window art, medieval cathedral style, lead outline segments, jewel tone colors, translucent effects, religious art influence, geometric patterns, Gothic architecture elements, backlit glowing effect, detailed iconography'
        },
        {
            id: 'ink-wash',
            name: 'Chinese Ink Wash',
            description: 'Traditional Chinese ink wash painting style',
            stylePrompt: 'Chinese ink wash painting, traditional brushwork, minimalist composition, careful balance of light and dark, mountain and water elements, bamboo brush technique, subtle gradients, zen aesthetic, negative space utilization, traditional landscape elements'
        },
        {
            id: 'silly-comics',
            name: 'Silly Comics',
            description: 'Super fun and wacky comic book style that will make kids laugh',
            stylePrompt: 'classic comic book style, super funny and ridicoulus. No texts'
        }
    ];

    getPresets(): StylePreset[] {
        return this.presets;
    }

    getPresetById(id: string): StylePreset | undefined {
        return this.presets.find(preset => preset.id === id);
    }
}