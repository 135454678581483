import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CustomLessonCreatorService {
    private customLessonSubject = new Subject<any>();
    customLesson$ = this.customLessonSubject.asObservable();
    private lessonData: any = {};

    constructor() { }

    saveCustomLesson(lessonData: any) {
        this.lessonData = lessonData;
        this.customLessonSubject.next(lessonData);
    }

    getLessonData() {
        return this.lessonData;
    }

    getCustomLessons() {
        return this.customLesson$;
    }
}
