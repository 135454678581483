import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgModule } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { SubMenuComponent } from './components/sub-menu/sub-menu.component';
import { FilterPipePipe } from './pipes/filter-pipe.pipe';
import { MenuGroupItemComponent } from './components/menu-group-item/menu-group-item.component';
import { MenuSubItemComponent } from './components/menu-sub-item/menu-sub-item.component';
import { CommonModule } from '@angular/common';
import { CheckRoleDirective } from './directives/check-role.directive';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { StudentDictionaryDialogComponent } from './components/student-dictionary-dialog/student-dictionary-dialog.component';
import { MatSliderModule } from '@angular/material/slider';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PoorInternetIndicatorComponent } from './components/poor-internet-indicator/poor-internet-indicator.component';
import { SpeechRecognitionComponent } from './components/speech-recognition/speech-recognition.component';
import { ChooseAvatarComponent } from './components/choose-avatar/choose-avatar.component';
import { StarSetterComponent } from './components/star-setter/star-setter.component';
import { SimpleDialogComponent } from './components/simple-dialog/simple-dialog.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CanvasWhiteboardModule } from 'ng2-canvas-whiteboard';
import { CanvasComponent } from './components/canvas/canvas.component';
import { CanvasControlsComponent } from './components/canvas/canvas-controls/canvas-controls.component';
import { UpdateConfirmationComponent } from './components/update-confirmation/update-confirmation.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AddEventTimeDialogComponent } from '../public-access/booking-form/add-event-time-dialog/add-event-time-dialog.component';
import { BookingFormComponent } from '../public-access/booking-form/booking-form.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { SanitizedHtmlPipe } from './pipes/sanitized-html.pipe';
import { DictionaryWebComponent } from './components/dictionary-web/dictionary-web.component';
import { DatetimePickerDialogComponent } from './compnents/datetime-picker-dialog/datetime-picker-dialog.component';
import { AddTaskComponent } from './components/add-task/add-task.component';
import { AddTaskDialogComponent } from './components/add-task/add-task-dialog/add-task-dialog.component';
import { TaskViewComponent } from './components/add-task/task-view/task-view.component';
import { SimpleTestComponent } from './simple-test/simple-test.component';
import { PoinsCounterComponent } from './components/poins-counter/poins-counter.component';
import { ChoosePointsItemComponent } from './components/choose-points-item/choose-points-item.component';
import { TasksListComponent } from './components/tasks-list/tasks-list.component';
import { WorkersTasksComponent } from './components/workers-tasks/workers-tasks.component';
import { UserAvatarSmallComponent } from './components/add-task/user-avatar-small/user-avatar-small.component';
import { CreateReportComponent } from './components/create-report/create-report.component';
import { EvaluateStudentComponent } from './components/evaluate-student/evaluate-student.component';
import { EvaluateStudentSelectComponent } from './components/evaluate-student-select/evaluate-student-select.component';
import { SignupModule } from '../public-access/signup-form/signup.module';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ScheduleAddGroupComponent } from './components/schedule/schedule-add-group/schedule-add-group.component';
import { PlanTempGroupComponent } from './components/plan-temp-group/plan-temp-group.component';
import { ScheduleAddGroupBarComponent } from './components/schedule/schedule-add-group/schedule-add-group-bar/schedule-add-group-bar.component';
import { PlanDayviewComponent } from './components/plan-temp-group/plan-dayview/plan-dayview.component';
import { OrderModule } from 'ngx-order-pipe'; // <- import OrderModule
import { PictureFinderComponent } from './components/picture-finder/picture-finder.component';
import { ProfileModule } from '../profile/profile.module';
import { ChatbotComponent } from '../profile/student/quiz/chatbot/chatbot.component';
import { SafeUrlPipe } from './pipes/safeUrl.pipe';
import { PiratesGameComponent } from '../profile/teacher/classroom-games/pirates-game/pirates-game.component';
import { SetsModule } from '../database/sets/sets.module';
import { MatStepperModule } from '@angular/material/stepper';
import { SentenceFromDisassembledComponent } from './components/sentence-from-disassembled/sentence-from-disassembled.component';
import { QuizWriteCheckComponent } from '../profile/student/quiz/quiz-write-check/quiz-write-check.component';
import { TestBedComponent } from './simple-test/test-bed/test-bed.component';
import { OverdueTasksDialogComponent } from './components/add-task/overdue-tasks-dialog/overdue-tasks-dialog.component';
import { SendSmsComponent } from './components/schedule/send-sms/send-sms.component';
import { OnlineLessonComponentComponent } from './components/OnlineLessonComponent/OnlineLessonComponent.component';
import { VideoMakerModule } from '../video-maker/video-maker.module';
import { ChatuiComponent } from './compnents/chatui/chatui.component';
import { CodeBlockComponent } from './compnents/chatui/code-block/code-block.component';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { AiImageGeneratorComponent } from './components/ai-image-generator/ai-image-generator.component';

@NgModule({
  declarations: [
    CodeBlockComponent,
    OnlineLessonComponentComponent,
    SendSmsComponent,
    OverdueTasksDialogComponent,
    PiratesGameComponent,
    PictureFinderComponent,
    ChatbotComponent,
    SafeUrlPipe,
    BookingFormComponent,
    AddEventTimeDialogComponent,
    StarSetterComponent,
    SubMenuComponent,
    FilterPipePipe,
    MenuGroupItemComponent,
    MenuSubItemComponent,
    CheckRoleDirective,
    StudentDictionaryDialogComponent,
    PoorInternetIndicatorComponent,
    SpeechRecognitionComponent,
    ChooseAvatarComponent,
    StarSetterComponent,
    SimpleDialogComponent,
    CanvasComponent,
    CanvasControlsComponent,
    UpdateConfirmationComponent,
    TextEditorComponent,
    SanitizedHtmlPipe,
    DictionaryWebComponent,
    DatetimePickerDialogComponent,
    AddTaskComponent,
    AddTaskDialogComponent,
    TaskViewComponent,
    SimpleTestComponent,
    PoinsCounterComponent,
    ChoosePointsItemComponent,
    TasksListComponent,
    WorkersTasksComponent,
    UserAvatarSmallComponent,
    CreateReportComponent,
    EvaluateStudentComponent,
    EvaluateStudentSelectComponent,
    ScheduleComponent,
    ScheduleAddGroupComponent,
    PlanTempGroupComponent,
    ScheduleAddGroupBarComponent,
    PlanDayviewComponent,
    SentenceFromDisassembledComponent,
    TestBedComponent,
    ChatuiComponent,
    AiImageGeneratorComponent,
    // SpeechBubbleComponent,
  ],
  imports: [

    PdfViewerModule,
    OrderModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgCircleProgressModule,
    AngularEditorModule,
    MatNativeDateModule,
    MatDatepickerModule,
    CanvasWhiteboardModule,
    CanvasWhiteboardModule,
    PdfViewerModule,
    MatSnackBarModule,
    DragDropModule,
    NgxSpinnerModule,
    MatSliderModule,
    MatBottomSheetModule,
    MatProgressBarModule,
    CommonModule,
    MatAutocompleteModule,
    // ScrollingModule,
    MatButtonToggleModule,
    OverlayModule,
    MatBadgeModule,
    MatTooltipModule,
    MatTabsModule,
    MatExpansionModule,
    NgxPaginationModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatGridListModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatSortModule,
    MatPaginatorModule,
    MatTableModule,
    MatCheckboxModule,
    MatMenuModule,
    MatInputModule,
    MatCardModule,
    FlexLayoutModule,
    MatButtonModule,
    MatStepperModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatRadioModule,

  ],
  exports: [
    OnlineLessonComponentComponent,
    SendSmsComponent,
    PiratesGameComponent,
    MatStepperModule,
    ScheduleComponent,

    PdfViewerModule,
    PictureFinderComponent,
    ChatbotComponent,
    OrderModule,
    NgCircleProgressModule,
    AngularEditorModule,
    BookingFormComponent,
    AddEventTimeDialogComponent,
    NgxMaterialTimepickerModule,
    MatNativeDateModule,
    MatDatepickerModule,
    CanvasWhiteboardModule,
    CanvasComponent,
    CanvasControlsComponent,
    StarSetterComponent,
    PdfViewerModule,
    ChooseAvatarComponent,
    SpeechRecognitionComponent,
    MatSnackBarModule,
    DragDropModule,
    NgxSpinnerModule,
    MatSliderModule,
    MatBottomSheetModule,
    MatProgressBarModule,
    MenuGroupItemComponent,
    MenuSubItemComponent,
    SubMenuComponent,
    ScrollingModule,
    MatButtonToggleModule,
    OverlayModule,
    MatBadgeModule,
    MatTooltipModule,
    MatTabsModule,
    MatExpansionModule,
    NgxPaginationModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatGridListModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatSortModule,
    MatPaginatorModule,
    MatTableModule,
    MatCheckboxModule,
    MatMenuModule,
    MatInputModule,
    MatCardModule,
    FlexLayoutModule,
    MatButtonModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatRadioModule,
    FilterPipePipe,
    MatAutocompleteModule,
    UserAvatarSmallComponent,
    SafeUrlPipe,
    SentenceFromDisassembledComponent,
    AiImageGeneratorComponent,
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'pl-PL' },

  ],
})
export class SharedModule { }
