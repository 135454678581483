<div class="groups-container">
  <div class="d-flex flex-row">
    <button
      (click)="previousGroup()"
      [disabled]="currentGroupIndex === 0"
      title="Go to previous group"
    >
      <mat-icon>arrow_back</mat-icon>
    </button>
    <div class="group-buttons-scrollable" #scrollableContainer>
      <div class="group-buttons">
        <div class="overflow-auto">
          <button
            *ngFor="let group of groupedLessons; let i = index"
            (click)="selectGroup(i)"
            [class.selected]="i === currentGroupIndex"
          >
            {{ group.groupName }}
          </button>
        </div>
      </div>
    </div>
    <button
      (click)="nextGroup()"
      [disabled]="currentGroupIndex === groupedLessons.length - 1"
      title="Go to next group"
    >
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </div>
</div>
<app-card-container
  class="mt-2"
  [group]="groupedLessons[currentGroupIndex]"
></app-card-container>
