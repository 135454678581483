<mat-card class="responsive-card">
  <div class="card-header">
    <h2 class="section-title">Teacher Absences</h2>
    <p class="teacher-name">{{ teacher?.name }}</p>
  </div>

  <mat-divider></mat-divider>

  <div class="content-container">
    <form
      [formGroup]="absenceForm"
      (ngSubmit)="addAbsence()"
      class="inline-form"
    >
      <div class="form-row">
        <mat-form-field appearance="outline">
          <mat-label>Start Date</mat-label>
          <input
            matInput
            [matDatepicker]="startPicker"
            formControlName="startDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>End Date</mat-label>
          <input
            matInput
            [matDatepicker]="endPicker"
            formControlName="endDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #endPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="reason-field">
          <mat-label>Reason</mat-label>
          <input matInput formControlName="reason" />
        </mat-form-field>

        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="!absenceForm.valid"
        >
          Add Absence
        </button>
      </div>
    </form>

    <div class="section-container">
      <div class="sets-list scrollable-container">
        <div class="sets-container">
          <div class="set-item" *ngFor="let absence of absences; let i = index">
            <div class="absence-info">
              <h4>
                {{ absence.startDate | date: "EEE, MMM d, y" }} -
                {{ absence.endDate | date: "EEE, MMM d, y" }}
              </h4>
              <p>{{ absence.reason }}</p>
              <p class="added-at">
                Added: {{ absence.addedAt | date: "EEEE, MMM d, y, h:mm a" }}
              </p>
            </div>
            <div class="actions">
              <button mat-icon-button color="warn" (click)="removeAbsence(i)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>
