import { Component, Input } from '@angular/core';
import { Teacher } from '../../shared/interfaces/teacher.interface';

@Component({
  selector: 'app-teacher-tab-content',
  templateUrl: './teacher-tab-content.component.html',
  styleUrls: ['./teacher-tab-content.component.css']
})
export class TeacherTabContentComponent {
  @Input() teacher!: Teacher;
  activeTab: 'lessons' | 'courses' | 'absences' | 'resignations' = 'lessons';

  selectTab(tab: 'lessons' | 'courses' | 'absences' | 'resignations') {
    this.activeTab = tab;
  }
}
