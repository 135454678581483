import { Component, Input, OnInit } from '@angular/core';
import { Teacher } from '../../shared/interfaces/teacher.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsersServiceService } from '../../shared/services/users-service.service';

interface StudentResignation {
  studentName: string;
  reason: string;
  addedAt: Date;
}

@Component({
  selector: 'app-students-resignations',
  templateUrl: './students-resignations.component.html',
  styleUrls: ['./students-resignations.component.css']
})
export class StudentsResignationsComponent implements OnInit {
  @Input() teacher!: Teacher;
  resignationForm: FormGroup;
  resignations: StudentResignation[] = [];

  constructor(
    private fb: FormBuilder,
    private usersService: UsersServiceService
  ) {
    this.resignationForm = this.fb.group({
      studentName: ['', Validators.required],
      reason: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    if (this.teacher?.id) {
      // Load existing resignations from user data
      this.loadResignations();
    }
  }

  private loadResignations(): void {
    if (this.teacher.userData?.resignations) {
      this.resignations = this.teacher.userData.resignations;
    }
  }

  addResignation(): void {
    if (this.resignationForm.valid) {
      const newResignation: StudentResignation = {
        ...this.resignationForm.value,
        addedAt: new Date()
      };
      this.resignations = [...this.resignations, newResignation];
      this.saveResignations();
      this.resignationForm.reset();
    }
  }

  removeResignation(index: number): void {
    this.resignations = this.resignations.filter((_, i) => i !== index);
    this.saveResignations();
  }

  private saveResignations(): void {
    if (this.teacher?.id) {
      this.usersService.setUsersProp(this.teacher.id, 'resignations', this.resignations);
    }
  }
}
