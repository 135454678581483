<mat-card class="responsive-card">
  <div class="card-header">
    <h2 class="section-title">Students Resignations</h2>
    <p class="teacher-name">{{ teacher?.name }}</p>
  </div>

  <mat-divider></mat-divider>

  <div class="content-container">
    <form
      [formGroup]="resignationForm"
      (ngSubmit)="addResignation()"
      class="inline-form"
    >
      <div class="form-row">
        <mat-form-field appearance="outline">
          <mat-label>Student Name</mat-label>
          <input
            matInput
            formControlName="studentName"
            placeholder="Enter student name"
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="reason-field">
          <mat-label>Reason</mat-label>
          <input
            matInput
            formControlName="reason"
            placeholder="Enter resignation reason"
          />
        </mat-form-field>

        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="!resignationForm.valid"
        >
          Add Resignation
        </button>
      </div>
    </form>

    <div class="section-container">
      <div class="sets-list scrollable-container">
        <div class="sets-container">
          <div
            class="set-item"
            *ngFor="let resignation of resignations; let i = index"
          >
            <div class="resignation-info">
              <h4>{{ resignation.studentName }}</h4>
              <p>{{ resignation.reason }}</p>
              <p class="added-at">
                Added:
                {{ resignation.addedAt | date: "EEEE, MMM d, y, h:mm a" }}
              </p>
            </div>
            <div class="actions">
              <button
                mat-icon-button
                color="warn"
                (click)="removeResignation(i)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>
