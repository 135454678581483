<mat-card *ngIf="group" class="responsive-card">
  <div class="card-header">
    <h2 class="group-name">{{ group.groupName }}</h2>
    <h3 class="ml-3" style="margin: 0px">{{ group.group.grade }}</h3>
    <span style="flex: 1"></span>
    <p class="date">
      {{
        group.lessons[group.currentLessonIndex].date
          | date: "EEEE, dd MMMM yyyy" : "pl"
      }}
    </p>
    <p class="ml-3" style="margin: 0px">
      {{ group.lessons[group.currentLessonIndex].lessonDuration + " min" }}
    </p>
  </div>

  <mat-divider></mat-divider>

  <mat-tab-group>
    <mat-tab label="Zestawy dodane przez lektora">
      <div class="section-container">
        <div class="sets-list scrollable-container">
          <div class="sets-container">
            <div
              [class.highlight]="
                i < group.group.sets.length - 1 &&
                isMoreThanAWeek(group.group.sets[i + 1].added, set.added)
              "
              [class.same-day]="hasSameDaySet(set, i)"
              class="set-item"
              *ngFor="let set of group.group.sets; let i = index"
            >
              <h4>
                {{ set.name.toUpperCase() }}
              </h4>
              <div class="d-flex flex-row">
                <p>{{ set.added | date }} |</p>
                <p class="ml-2">{{ set.items.length }} Elementów</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Uczniowie">
      <div class="section-container">
        <div class="sets-list scrollable-container">
          <div class="sets-container">
            <div
              class="student-item"
              *ngFor="let student of group.studentsData"
            >
              <div class="student-info">
                <h4>{{ student.name }}</h4>
                <p>
                  {{
                    "Ostatnia nauka: " +
                      (student.userData.lastResetProgress
                        | date: "EEEE, dd MMMM yyyy" : "pl")
                  }}
                </p>
              </div>
              <div class="attendance-status">
                <span
                  [style.color]="
                    isStudentPresent(student.name) ? 'green' : 'red'
                  "
                >
                  {{ isStudentPresent(student.name) ? "Obecny" : "Nieobecny" }}
                  <mat-icon>
                    {{
                      isStudentPresent(student.name) ? "check_circle" : "cancel"
                    }}
                  </mat-icon>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Lekcje">
      <div class="section-container">
        <div class="lesson-details">
          <ng-container
            *ngIf="
              group?.lessons &&
              group?.currentLessonIndex >= 0 &&
              group?.lessons[group?.currentLessonIndex]
                ?.lessonData as lessonData
            "
          >
            <!-- Activities comparison -->
            <div class="comparison-item">
              <h4>Aktywności</h4>
              <div class="comparison-content">
                <div class="comparison-lists">
                  <div class="list-section">
                    <h5>Wykonane aktywności:</h5>
                    <div *ngFor="let activity of lessonData?.activities || []">
                      <p>{{ activity?.activityData?.name || "Brak nazwy" }}</p>
                    </div>
                    <p *ngIf="!lessonData?.activities?.length">
                      Brak aktywności
                    </p>
                  </div>
                  <div class="list-section">
                    <h5>Zaplanowane aktywności:</h5>
                    <div
                      *ngFor="let activity of getPlannedActivities(lessonData)"
                    >
                      <p>{{ activity?.name || "Brak nazwy" }}</p>
                    </div>
                    <p *ngIf="hasPlannedActivities(lessonData)">
                      Brak zaplanowanych aktywności
                    </p>
                  </div>
                </div>
                <div class="comparison-status">
                  <mat-icon
                    [style.color]="
                      activitiesMatch
                        ? 'green'
                        : partialActivitiesMatch
                        ? 'green'
                        : 'red'
                    "
                  >
                    {{
                      activitiesMatch
                        ? "check_circle"
                        : partialActivitiesMatch
                        ? "check_circle"
                        : "cancel"
                    }}
                  </mat-icon>
                </div>
              </div>
            </div>

            <!-- Sets comparison -->
            <div class="comparison-item">
              <h4>Zestawy</h4>
              <div class="comparison-content">
                <div class="comparison-lists">
                  <div class="list-section">
                    <h5>Użyte zestawy:</h5>
                    <div *ngFor="let set of lessonData?.sets || []">
                      <p>{{ set?.set?.name || "Brak nazwy" }}</p>
                    </div>
                    <p *ngIf="!lessonData?.sets?.length">Brak zestawów</p>
                  </div>
                  <div class="list-section">
                    <h5>Zaplanowane zestawy:</h5>
                    <div *ngFor="let set of getPlannedSets(lessonData)">
                      <p>{{ set?.name || "Brak nazwy" }}</p>
                    </div>
                    <p *ngIf="hasPlannedSets(lessonData)">
                      Brak zaplanowanych zestawów
                    </p>
                  </div>
                </div>
                <div class="comparison-status">
                  <mat-icon [style.color]="setsMatch ? 'green' : 'red'">
                    {{ setsMatch ? "check_circle" : "cancel" }}
                  </mat-icon>
                </div>
              </div>
            </div>

            <!-- Homework comparison -->
            <div class="comparison-item">
              <h4>Praca domowa</h4>
              <div class="comparison-content">
                <div class="comparison-lists">
                  <div class="list-section">
                    <h5>Zadana praca:</h5>
                    <div *ngFor="let hw of lessonData?.homework || []">
                      <p>{{ hw?.homeworkData?.displayName || "Brak nazwy" }}</p>
                    </div>
                    <p *ngIf="!lessonData?.homework?.length">
                      Brak zadanej pracy
                    </p>
                  </div>
                  <div class="list-section">
                    <h5>Zaplanowana praca:</h5>
                    <div *ngFor="let hw of getPlannedHomework(lessonData)">
                      <p>{{ hw?.displayName || "Brak nazwy" }}</p>
                    </div>
                    <p *ngIf="hasPlannedHomework(lessonData)">
                      Brak zaplanowanej pracy
                    </p>
                  </div>
                </div>
                <div class="comparison-status">
                  <mat-icon [style.color]="homeworkMatch ? 'green' : 'red'">
                    {{ homeworkMatch ? "check_circle" : "cancel" }}
                  </mat-icon>
                </div>
              </div>
            </div>

            <!-- Comments section -->
            <div class="comparison-item">
              <h4>Komentarze</h4>
              <div class="comparison-content">
                <div class="comparison-lists">
                  <div class="list-section">
                    <h5>Komentarze do lekcji:</h5>
                    <div *ngFor="let comment of lessonData?.comments || []">
                      <p>{{ comment || "Brak komentarza" }}</p>
                    </div>
                    <p *ngIf="!lessonData?.comments?.length">Brak komentarzy</p>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div
            *ngIf="
              !group?.lessons ||
              !group?.lessons[group?.currentLessonIndex]?.lessonData
            "
            class="no-data-message"
          >
            <p>Brak danych do porównania</p>
          </div>
        </div>

        <div class="dots-section">
          <div class="dots-container">
            <span
              class="dot"
              *ngFor="let lesson of group?.lessons || []; let i = index"
              [style.color]="
                i === group?.currentLessonIndex
                  ? lesson?.activeColor
                  : lesson?.inactiveColor
              "
            >
              &#9679;
            </span>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div class="navigation-buttons">
    <button mat-icon-button (click)="nextLesson()" aria-label="Next lesson">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
  <div class="previous-lesson-button">
    <button
      mat-icon-button
      (click)="previousLesson()"
      aria-label="Previous lesson"
    >
      <mat-icon>chevron_left</mat-icon>
    </button>
  </div>
</mat-card>
