import { Component, EventEmitter, Input, Output, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChatService } from '../../services/chat.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChatbotsService } from '../../services/chatbots.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ImageStylePresetsService, StylePreset } from '../../services/image-style-presets.service';

@Component({
    selector: 'app-ai-image-generator',
    templateUrl: './ai-image-generator.component.html',
    styleUrls: ['./ai-image-generator.component.scss']
})
export class AiImageGeneratorComponent {
    @Input() loading = false;
    @Output() generateImage = new EventEmitter<string>();
    @Output() extendPrompt = new EventEmitter<string>();

    imageForm: FormGroup;
    isExtending = false;
    isGenerating = false;
    generatedImage: SafeUrl | null = null;
    rawGeneratedImage: string | null = null;
    lastMessage: string | null = null;
    stylePresets: StylePreset[] = [];
    selectedPresetId: string | null = null;

    constructor(
        private fb: FormBuilder,
        private chatService: ChatService,
        public dialogRef: MatDialogRef<AiImageGeneratorComponent>,
        private chatbotsService: ChatbotsService,
        private sanitizer: DomSanitizer,
        private stylePresetsService: ImageStylePresetsService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.imageForm = this.fb.group({
            prompt: ['', [Validators.required, Validators.minLength(3)]],
            stylePreset: [null]
        });
        this.stylePresets = this.stylePresetsService.getPresets();
    }

    onSubmit(): void {
        if (this.imageForm.valid) {
            const prompt = this.imageForm.get('prompt')?.value;
            const presetId = this.imageForm.get('stylePreset')?.value;
            const preset = presetId ? this.stylePresetsService.getPresetById(presetId) : null;

            const finalPrompt = preset
                ? `${prompt}. Style: ${preset.stylePrompt}`
                : prompt;

            this.generateImageFromPrompt(finalPrompt);
        }
    }

    generateImageFromPrompt(prompt: string): void {
        this.isGenerating = true;
        this.lastMessage = 'Generating image...';
        const selectedChat = this.chatbotsService.chatbots.find(chat => chat.name === 'Tom') || this.chatbotsService.chatbots[0];

        const messages = [{
            role: 'user',
            content: `Generate an image based on this description: ${prompt}. Respond with base64 encoded image data only, without any prefix.`
        }];

        const data = {
            messages,
            aiProvider: selectedChat.aiProvider,
            model: selectedChat.model,
            maxTokens: 1000,
            maxMessages: 1,
            systemMsg: 'You are an AI image generation assistant. Generate images based on descriptions and return them in base64 format without any prefixes or additional text.',
            files: null
        };

        this.chatService.sendPromptToChosenAi(data).subscribe(
            (response) => {
                let base64Data = response.res.content;
                if (base64Data) {
                    try {
                        base64Data = base64Data.replace(/^[^,]*,/, '');
                        base64Data = base64Data.trim();
                        const dataUrl = `data:image/png;base64,${base64Data}`;

                        if (base64Data.match(/^[A-Za-z0-9+/=]+$/)) {
                            this.rawGeneratedImage = dataUrl;
                            this.generatedImage = this.sanitizer.bypassSecurityTrustUrl(dataUrl);
                            this.lastMessage = null;
                        } else {
                            throw new Error('Invalid base64 data');
                        }
                    } catch (error) {
                        console.error('Error processing image data:', error);
                        this.lastMessage = 'Failed to process image. Try a simpler prompt.';
                    }
                } else {
                    this.lastMessage = 'No image data received. Please try again.';
                }
                this.isGenerating = false;
            },
            (error) => {
                console.error('Error generating image:', error);
                this.lastMessage = error.status === 413
                    ? 'The image was too large. Try a simpler prompt.'
                    : 'Error generating image. Please try again.';
                this.isGenerating = false;
            }
        );
    }

    acceptImage(): void {
        if (this.rawGeneratedImage) {
            this.dialogRef.close({
                image: this.rawGeneratedImage,
                prompt: this.imageForm.get('prompt')?.value,
                stylePreset: this.stylePresets.find(preset => preset.id === this.imageForm.get('stylePreset')?.value)
            });
        }
    }

    onExtendPrompt(): void {
        if (this.imageForm.valid) {
            this.isExtending = true;
            const prompt = this.imageForm.get('prompt')?.value;
            const presetId = this.imageForm.get('stylePreset')?.value;
            const preset = presetId ? this.stylePresetsService.getPresetById(presetId) : null;

            const promptToEnhance = preset
                ? `${prompt}. Consider this style: ${preset.stylePrompt}`
                : prompt;

            const selectedChat = this.chatbotsService.chatbots.find(chat => chat.name === 'Tom') || this.chatbotsService.chatbots[0];

            const messages = [{
                role: 'user',
                content: `Enhance this image description to make it more detailed and creative: "${promptToEnhance}". Keep the same general idea but add more specific details about lighting, composition, style, and atmosphere.`
            }];

            const data = {
                messages,
                aiProvider: 'openai',
                model: 'gpt-4o',
                maxTokens: 500,
                maxMessages: 1,
                systemMsg: 'You are a creative assistant helping to enhance image descriptions for AI image generation. Keep responses concise and focused on visual details.',
                files: null
            };

            this.chatService.sendPromptToChosenAi(data).subscribe(
                (response) => {
                    const enhancedPrompt = response.res.content;
                    this.setPrompt(enhancedPrompt);
                    this.isExtending = false;
                },
                (error) => {
                    console.error('Error extending prompt:', error);
                    this.lastMessage = 'Failed to enhance description. Please try again.';
                    this.isExtending = false;
                }
            );
        }
    }

    setPrompt(prompt: string): void {
        this.imageForm.patchValue({ prompt });
    }

    reset(): void {
        this.imageForm.reset();
        this.generatedImage = null;
        this.lastMessage = null;
        this.selectedPresetId = null;
        this.isGenerating = false;
        this.isExtending = false;
    }
}