import { Component, OnInit, Input } from "@angular/core";
import { ThemePalette } from "@angular/material/core";
import { Teacher } from "src/app/shared/interfaces/teacher.interface";
import { TeachersEvaluationService } from "src/app/shared/services/teachers-evaluation.service";

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {
  @Input() teacher!: Teacher;
  coursesTimeThisMonth = 0;
  coursesTimeExpected = 120;
  trainingPercentage = 0;
  trainingHistory: { month: string, time: number }[] = [];

  constructor(private teacherEvaluationService: TeachersEvaluationService) { }

  ngOnInit(): void {
    console.log("🚀 ~ CoursesComponent ~ teacher:", this.teacher);
    this.coursesTimeThisMonth = this.teacher.userData.timeInTrainingThisMonth;
    this.calculateTrainingPercentage();
    this.formatTrainingHistory();
  }

  calculateTrainingPercentage(): void {
    this.trainingPercentage = (this.coursesTimeThisMonth / this.coursesTimeExpected) * 100;
  }

  formatTrainingHistory(): void {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Reset trainingHistory to start fresh
    this.trainingHistory = [];

    // Add current month's progress
    this.trainingHistory.push({
      month: `${currentDate.toLocaleString('default', { month: 'long' })} ${currentYear}`,
      time: this.teacher.userData.timeInTrainingThisMonth || 0 // Default to 0 if undefined
    });

    // Check if timeInTrainingHistory is defined and is an array
    if (Array.isArray(this.teacher.userData.timeInTrainingHistory)) {
      // Format historical data
      const historicalMonths = this.teacher.userData.timeInTrainingHistory.map((time, index) => {
        const month = (currentMonth - index - 1 + 12) % 12; // Calculate previous months
        const year = currentYear - Math.floor((currentMonth - index - 1) / 12); // Calculate year
        return {
          month: `${new Date(year, month).toLocaleString('default', { month: 'long' })} ${year}`,
          time: time
        };
      });

      // Add historical months to trainingHistory
      this.trainingHistory.push(...historicalMonths);
    } else {
      console.warn('timeInTrainingHistory is not defined or is not an array');
    }
  }

  getProgressColor(percentage: number): ThemePalette {
    if (percentage < 50) {
      return 'warn';
    } else if (percentage < 80) {
      return 'accent';
    } else {
      return 'primary';
    }
  }
}
