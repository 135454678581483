// lessons-display.component.ts
import { Component, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, OnChanges, SimpleChanges, Input } from '@angular/core';

@Component({
    selector: 'app-lessons-display',
    templateUrl: './lessons-display.component.html',
    styleUrls: ['./lessons-display.component.css']
})
export class LessonsDisplayComponent implements AfterViewInit, OnChanges {
    @ViewChild('scrollableContainer') scrollableContainer: ElementRef;
    @Input() groupedLessons = [];

    currentGroupIndex = 0;

    constructor(private cdr: ChangeDetectorRef) { }

    ngAfterViewInit() {
        this.scrollToCurrentGroup();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.groupedLessons && changes.groupedLessons.currentValue.length > 0) {
            this.cdr.detectChanges();
            this.scrollToCurrentGroup();
        }
    }

    selectGroup(index: number) {
        this.currentGroupIndex = index;
        this.scrollToCurrentGroup();
    }

    previousGroup() {
        if (this.currentGroupIndex > 0) {
            this.currentGroupIndex--;
            this.scrollToCurrentGroup();
        }
    }

    nextGroup() {
        if (this.currentGroupIndex < this.groupedLessons.length - 1) {
            this.currentGroupIndex++;
            this.scrollToCurrentGroup();
        }
    }

    previousLesson() {
        const currentGroup = this.groupedLessons[this.currentGroupIndex];
        if (currentGroup && currentGroup.currentLessonIndex > 0) {
            currentGroup.currentLessonIndex--;
            this.scrollToCurrentGroup();
        }
    }

    nextLesson() {
        const currentGroup = this.groupedLessons[this.currentGroupIndex];
        if (currentGroup && currentGroup.currentLessonIndex < currentGroup.lessons.length - 1) {
            currentGroup.currentLessonIndex++;
            this.scrollToCurrentGroup();
        }
    }

    private scrollToCurrentGroup() {
        if (!this.scrollableContainer) return;
        const container = this.scrollableContainer.nativeElement;
        const selectedButton = container.querySelector('.selected');
        if (selectedButton) {
            container.scrollLeft =
                selectedButton.offsetLeft - container.offsetWidth / 2 + selectedButton.offsetWidth / 2;
        }
    }
}
