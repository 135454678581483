<mat-card>
  <mat-card-content>
    <div *ngFor="let entry of trainingHistory" class="training-entry">
      <mat-card-subtitle>{{ entry.month }}</mat-card-subtitle>
      <mat-progress-bar
        [value]="(entry.time / coursesTimeExpected) * 100"
        [color]="getProgressColor((entry.time / coursesTimeExpected) * 100)"
      >
      </mat-progress-bar>
      <div class="time-label">{{ entry.time }} min</div>
    </div>
  </mat-card-content>
</mat-card>
