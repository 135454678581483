import { Component, OnInit } from '@angular/core';
import { TeachersEvaluationService } from '../shared/services/teachers-evaluation.service';
import { Teacher } from '../shared/interfaces/teacher.interface';

@Component({
    selector: 'app-teachers-evaluation',
    templateUrl: './teachers-evaluation.component.html',
    styleUrls: ['./teachers-evaluation.component.css']
})
export class TeachersEvaluationComponent implements OnInit {
    teachers: Teacher[] = [];
    selectedTeacher: Teacher | null = null;
    viewMode: 'all' | 'single' = 'all';

    constructor(private teachersEvaluationService: TeachersEvaluationService) { }

    ngOnInit(): void {
        this.teachersEvaluationService.getTeachersUpdates().subscribe((teachers: Teacher[]) => {
            this.teachers = teachers;

        });
    }

    selectTeacher(teacher: Teacher) {
        this.selectedTeacher = teacher;
    }
}