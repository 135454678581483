import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChatService } from 'src/app/shared/services/chat.service';
import * as hljs from 'highlight.js';
import * as showdown from 'showdown';
import { ChatbotsService } from '../../services/chatbots.service';
import { UserDetailsService } from 'src/app/profile/user-details.service';
import { WorkersAvatarsService } from '../../services/workers-avatars.service';
import { MatSelectChange } from '@angular/material/select';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HelpersService } from '../../services/helpers.service';
import { CustomLessonCreatorService } from '../../../groups/groups-lessons/custom-lesson-creator/custom-lesson-creator.service';
import { LessonActivitiesService } from '../../services/lesson-activities.service';

@Component({
  selector: 'app-chatui',
  templateUrl: './chatui.component.html',
  styleUrls: ['./chatui.component.scss']
})
export class ChatuiComponent implements OnInit {
  message = '';
  isMobile = false;
  private converter: showdown.Converter;
  usersAvatar;
  files: File[] = [];
  conversation = [];
  chatbots = this.chatbotsService.chatbots;
  selectedChatbot = this.chatbots[0];
  usersFirstName;
  lastUploadedImage: any = null;

  @ViewChild('chatMessages') private chatMessages!: ElementRef;

  constructor(private userDetailsService: UserDetailsService,
    private aiService: ChatService, private snackBar: MatSnackBar,
    private chatbotsService: ChatbotsService,
    private workersAvatarsService: WorkersAvatarsService,
    private sanitizer: DomSanitizer,
    private helpers: HelpersService,
    private customLessonService: CustomLessonCreatorService,
    private lessonActivitiesService: LessonActivitiesService) {
    this.converter = new showdown.Converter({
      tables: true,
      tasklists: true,
      strikethrough: true,
      emoji: true,
      parseImgDimensions: true,
      simpleLineBreaks: true,
      openLinksInNewWindow: true
    });
    hljs.configure({
      languages: ['javascript', 'typescript', 'python', 'java', 'cpp', 'csharp', 'php', 'ruby', 'go', 'rust', 'sql', 'bash', 'html', 'css', 'json', 'yaml', 'markdown']
    });
  }

  ngOnInit(): void {
    this.conversation.forEach((message: { role: string; content: string; splitContent?: any }) => {
      message.splitContent = this.splitContent(this.helpers.clone(message.content));
    });

    this.isMobile = window.innerWidth < 768;
    this.updateUserDetails();
  }

  private updateUserDetails(): void {
    const userDetails = this.userDetailsService.getSimpleUserDetails();
    const newFirstName = userDetails.name.split(' ')[0];
    const newAvatar = this.workersAvatarsService.getAvatarURL(userDetails.name);

    if (newFirstName !== this.usersFirstName || newAvatar !== this.usersAvatar) {
      this.usersFirstName = newFirstName;
      this.usersAvatar = newAvatar;
    }
  }

  onFileSelected(event: any) {
    this.files = event.target.files;
    const file = this.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = () => {
        // Don't add image preview to conversation immediately
        // Store it temporarily
        this.lastUploadedImage = {
          type: 'image',
          value: reader.result,
          alt: 'Uploaded image'
        };
        // Automatically trigger send to include the image
        this.sendMessage();
      };
      reader.readAsDataURL(file);
    }
    this.snackBar.open(`${this.files.length} file(s) selected`, 'Close', { duration: 3000 });
  }

  sendMessage() {
    if (!this.message.trim() && !this.lastUploadedImage && this.files.length === 0) return;

    const messageContent = this.message;
    const splitContent = [];

    // Add text content if exists
    if (messageContent.trim()) {
      splitContent.push(...this.splitContent(messageContent));
    }

    // Add image if exists
    if (this.lastUploadedImage) {
      splitContent.push(this.lastUploadedImage);
    }

    const userMessage = {
      role: 'user',
      content: messageContent,
      splitContent: splitContent
    };

    this.conversation.push(userMessage);
    this.scrollToBottom();

    const conversationForAI = this.conversation.map(msg => ({
      role: msg.role,
      content: msg.content
    }));

    const data = {
      messages: conversationForAI,
      aiProvider: this.selectedChatbot.aiProvider,
      model: this.selectedChatbot.model,
      maxTokens: this.selectedChatbot.maxTokens,
      maxMessages: this.selectedChatbot.maxMessages,
      systemMsg: this.selectedChatbot.systemMsg,
      files: this.files,
      studentInfo: {
        level: this.customLessonService.getLessonData().studentLevel,
        concentration: this.customLessonService.getLessonData().studentConcentration,
        learningStyle: this.customLessonService.getLessonData().learningStyle
      }
    };

    this.message = '';
    this.lastUploadedImage = null; // Reset the temporary image storage
    this.aiService.sendPromptToChosenAi(data).subscribe(
      (res) => {
        const aiResponse = {
          ...res.res,
          splitContent: this.splitContent(this.helpers.clone(res.res.content))
        };
        this.conversation.push(aiResponse);

        // Create and save the activity



        this.message = '';
        this.files = [];
        this.scrollToBottom();
      },
      (error) => {
        console.error('Error:', error);
        this.snackBar.open('An error occurred while sending the message', 'Close', { duration: 5000 });
      }
    );
  }

  selectChatbot(event: MatSelectChange) {
    if (event && event.value) {
      this.selectedChatbot = event.value;
      this.startNewConversation();
      this.snackBar.open(`Chatbot changed to ${this.selectedChatbot.name}`, 'Close', { duration: 3000 });
    }
  }

  splitContent(content) {
    const parts: { type: 'text' | 'code' | 'image', value: string | SafeHtml, language?: string, alt?: string }[] = [];

    // Handle code blocks and markdown images
    const regex = /```(\w+)?\s*([\s\S]*?)```|!\[([^\]]*)\]\((data:image\/[^;]+;base64,[^)]+)\)/g;
    let lastIndex = 0;
    let match;

    while ((match = regex.exec(content)) !== null) {
      // Add text before the match
      if (match.index > lastIndex) {
        const textContent = content.substring(lastIndex, match.index);
        // Check for base64 in text content
        const processedText = this.processBase64InText(textContent);
        parts.push(...processedText);
      }

      if (match[0].startsWith('```')) {
        parts.push({ type: 'code', value: match[2], language: match[1] || 'plaintext' });
      } else if (match[0].startsWith('![')) {
        parts.push({ type: 'image', value: match[4], alt: match[3] });
      }

      lastIndex = match.index + match[0].length;
    }

    // Process remaining content
    if (lastIndex < content.length) {
      const remainingText = content.substring(lastIndex);
      const processedRemaining = this.processBase64InText(remainingText);
      parts.push(...processedRemaining);
    }

    // Convert text parts to HTML
    parts.forEach(part => {
      if (part.type === 'text') {
        const safeHtml = this.sanitizer.bypassSecurityTrustHtml(this.converter.makeHtml(part.value));
        part.value = safeHtml;
      }
    });

    return parts;
  }

  private processBase64InText(text: string): { type: 'text' | 'image', value: string, alt?: string }[] {
    const parts = [];
    // Match common base64 image patterns
    const base64Regex = /(iVBORw0KGgo[A-Za-z0-9+/=]+|\/9j\/[A-Za-z0-9+/=]+)/g;
    let lastIndex = 0;
    let match;

    while ((match = base64Regex.exec(text)) !== null) {
      // Add text before the base64 string
      if (match.index > lastIndex) {
        parts.push({ type: 'text', value: text.substring(lastIndex, match.index) });
      }

      // Add the base64 string as an image
      const imageData = match[1];
      const imageType = imageData.startsWith('iVBORw') ? 'png' : 'jpeg';
      const dataUrl = `data:image/${imageType};base64,${imageData}`;
      parts.push({ type: 'image', value: dataUrl, alt: 'Generated image' });

      lastIndex = match.index + match[0].length;
    }

    // Add remaining text
    if (lastIndex < text.length) {
      parts.push({ type: 'text', value: text.substring(lastIndex) });
    }

    return parts;
  }

  startNewConversation() {
    this.conversation = [];
    this.snackBar.open('New conversation started', 'Close', { duration: 3000 });
  }

  downloadImage(base64Data: string, altText: string): void {
    try {
      // Remove the data URL prefix if present
      const base64Content = base64Data.includes('base64,') ? base64Data.split('base64,')[1] : base64Data;

      // Convert base64 to blob
      const byteCharacters = atob(base64Content);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'image/png' });

      // Create download link
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${altText || 'image'}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);

      this.snackBar.open('Image downloaded successfully', 'Close', { duration: 3000 });
    } catch (error) {
      console.error('Error downloading image:', error);
      this.snackBar.open('Error downloading image', 'Close', { duration: 3000 });
    }
  }

  private scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.chatMessages.nativeElement.scrollTop = this.chatMessages.nativeElement.scrollHeight;
      }, 0);
    } catch (err) {
      console.error('Error scrolling to bottom:', err);
    }
  }
}
