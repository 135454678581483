import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';

import { TeachersEvaluationComponent } from './teachers-evaluation.component';
import { TeachersEvaluationService } from '../shared/services/teachers-evaluation.service';
import { TeacherTabContentComponent } from './teacher-tab-content/teacher-tab-content.component';
import { LessonsComponent } from './lessons/lessons.component';
import { LessonsDisplayComponent } from './lessons/lessons-display/lessons-display.component';
import { CoursesComponent } from './courses/courses.component';

import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { CardContainerComponent } from './lessons/card-container/card-container.component';
import { TeacherAbsencesComponent } from './teacher-absences/teacher-absences.component';
import { StudentsResignationsComponent } from './students-resignations/students-resignations.component';

@NgModule({
    declarations: [
        TeachersEvaluationComponent,
        TeacherTabContentComponent,
        LessonsComponent,
        LessonsDisplayComponent,
        CoursesComponent,
        CardContainerComponent,
        TeacherAbsencesComponent,
        StudentsResignationsComponent,
    ],
    imports: [
        CommonModule,
        RouterModule.forChild([
            { path: '', component: TeachersEvaluationComponent }
        ]),
        SharedModule,
        ReactiveFormsModule,
        MatGridListModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        LayoutModule
    ],
    providers: [TeachersEvaluationService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TeachersEvaluationModule { }
