import { Component, OnInit, Input, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { CustomersService } from 'src/app/shared/services/customers.service';
import { UsersServiceService } from 'src/app/shared/services/users-service.service';

@Component({
  selector: 'app-card-container',
  templateUrl: './card-container.component.html',
  styleUrls: ['./card-container.component.css']
})
export class CardContainerComponent implements OnInit {
  @Input() group: any;
  sub: any;
  sub2: any;

  // Comparison results
  activitiesMatch = false;
  partialActivitiesMatch = false;
  setsMatch = false;
  homeworkMatch = false;

  constructor(private cdr: ChangeDetectorRef, private usersService: UsersServiceService, private customersService: CustomersService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.group && changes.group.currentValue) {
      this.getStudentAppId();
      this.compareLessonWithPlan();
    }
  }

  compareLessonWithPlan(): void {
    if (!this.group?.lessons?.[this.group.currentLessonIndex]?.lessonData) {
      this.activitiesMatch = false;
      this.setsMatch = false;
      this.homeworkMatch = false;
      return;
    }

    const lessonData = this.group.lessons[this.group.currentLessonIndex].lessonData;
    const planItem = lessonData?.planItems?.[0]?.item;

    if (!planItem) {
      this.activitiesMatch = false;
      this.setsMatch = false;
      this.homeworkMatch = false;
      return;
    }

    // Compare activities
    this.activitiesMatch = this.compareActivities(lessonData?.activities, planItem?.activities);

    // Compare sets
    this.setsMatch = this.compareSets(lessonData?.sets, planItem?.sets);

    // Compare homework
    this.homeworkMatch = this.compareHomework(lessonData?.homework, planItem?.homework);
  }

  compareActivities(lessonActivities: any[] | undefined, planActivities: any[] | undefined): boolean {
    if (!Array.isArray(lessonActivities) || !Array.isArray(planActivities)) {
      this.partialActivitiesMatch = false;
      return false;
    }

    const matchingActivities = lessonActivities.filter(lessonActivity =>
      lessonActivity?.activityData?.id && planActivities.some(planActivity =>
        planActivity?.id === lessonActivity.activityData.id
      )
    );

    this.partialActivitiesMatch = matchingActivities.length > 0 &&
      (matchingActivities.length !== lessonActivities.length ||
        matchingActivities.length !== planActivities.length);

    return lessonActivities.length === planActivities.length &&
      matchingActivities.length === lessonActivities.length;
  }

  compareSets(lessonSets: any[] | undefined, planSets: any[] | undefined): boolean {
    if (!Array.isArray(lessonSets) || !Array.isArray(planSets)) return false;
    if (lessonSets.length !== planSets.length) return false;

    return lessonSets.every(lessonSet =>
      lessonSet?.set?.id && planSets.some(planSet =>
        planSet?.id === lessonSet.set.id
      )
    );
  }

  compareHomework(lessonHomework: any[] | undefined, planHomework: any[] | undefined): boolean {
    if (!Array.isArray(lessonHomework) || !Array.isArray(planHomework)) return false;
    if (lessonHomework.length !== planHomework.length) return false;

    return lessonHomework.every(lessonHw =>
      lessonHw?.homeworkData?.name && planHomework.some(planHw =>
        planHw?.name === lessonHw.homeworkData.name
      )
    );
  }

  // Helper methods for template
  getPlannedActivities(lessonData: any): any[] {
    return lessonData?.planItems?.[0]?.item?.activities || [];
  }

  getPlannedSets(lessonData: any): any[] {
    return lessonData?.planItems?.[0]?.item?.sets || [];
  }

  getPlannedHomework(lessonData: any): any[] {
    return lessonData?.planItems?.[0]?.item?.homework || [];
  }

  hasPlannedActivities(lessonData: any): boolean {
    return !lessonData?.planItems || !lessonData?.planItems[0]?.item?.activities?.length;
  }

  hasPlannedSets(lessonData: any): boolean {
    return !lessonData?.planItems || !lessonData?.planItems[0]?.item?.sets?.length;
  }

  hasPlannedHomework(lessonData: any): boolean {
    return !lessonData?.planItems || !lessonData?.planItems[0]?.item?.homework?.length;
  }
  ngOnInit(): void {
    this.sub2 = this.customersService.studentsForGroupUpdatedListener().subscribe((students: any) => {
      console.log('Students updated:', students);
      if (students && students.length > 0) {
        let appIds = students.map((student: any) => student.studentAppId);
        this.usersService.findUsers(appIds);
      }
    })


    this.sub = this.usersService.foundUserUpdatedListener().subscribe((users: any) => {
      console.log('Users updated:', users);
      if (users && users.length > 0) {
        console.log('Users updated:', users);
        this.group.studentsData = users;
      }
    })

  }
  hasSameDaySet(currentSet: any, index: number): boolean {
    if (!this.group || !this.group.group || !this.group.group.sets) return false;

    const currentDate = new Date(currentSet.added).toDateString();
    return this.group.group.sets.some((set: any, i: number) =>
      i !== index && new Date(set.added).toDateString() === currentDate
    );
  }
  isStudentPresent(studentName: string): boolean {

    return this.group.lessons[this.group.currentLessonIndex].lessonData?.attendance.some(user => user.name === studentName);

  }
  isSameDay(date1: string, date2: string): boolean {
    return new Date(date1).toDateString() === new Date(date2).toDateString();
  }

  isMoreThanAWeek(setDate: Date, previousSetDate: Date): boolean {

    if (!setDate || !previousSetDate) {
      return false;
    }

    const currentDate = new Date(setDate);
    const prevDate = new Date(previousSetDate);

    if (isNaN(currentDate.getTime()) || isNaN(prevDate.getTime())) {
      return false;
    }

    const oneWeekInMilliseconds = 8 * 24 * 60 * 60 * 1000;
    const diffInMilliseconds = prevDate.getTime() - currentDate.getTime()


    const result = diffInMilliseconds > oneWeekInMilliseconds;

    return result;
  }
  getStudentAppId() {
    if (this.group && this.group.students && this.group.students.length > 0) {
      const studentId = this.group.group.students[0]; // Assuming you want the first studentId
      // this.usersService.findUsers({ ids: [studentId] })
      this.customersService.getStudentsForGroup(this.group.students);

    }
  }

  previousLesson() {
    if (this.group && this.group.currentLessonIndex > 0) {
      this.group.currentLessonIndex--;
    }
  }

  nextLesson() {
    if (
      this.group &&
      this.group.currentLessonIndex < this.group.lessons.length - 1
    ) {
      this.group.currentLessonIndex++;
    }
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();
    this.sub2.unsubscribe();

  }
}
