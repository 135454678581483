<div fxLayout="row"></div>

<mat-card class="m-3">
  <div fxFlex="row">
    <div fxFlex="50">
      <form [formGroup]="imageForm" (submit)="onAddClicked()">
        <div class="image-container">
          <input
            style="padding-top: 145px; padding-left: 135px;"
            *ngIf="!imgLoaded"
            appAppDragDrop
            (onFileDropped)="fileChangeEvent($event)"
            type="file"
            (change)="fileChangeEvent($event)"
          />
          <div *ngIf="imgLoaded" style="height: 100%; display: flex; align-items: center; justify-content: center;">
            <img 
              *ngIf="!imageChangedEvent"
              [src]="imagePrv" 
              style="max-width: 100%; max-height: 100%; object-fit: contain;"
            >
            <image-cropper
              *ngIf="imageChangedEvent"
              [imageChangedEvent]="imageChangedEvent"
              [maintainAspectRatio]="true"
              [aspectRatio]="3508 / 2480"
              format="png"
              (imageCropped)="imageCropped($event)"
              (imageLoaded)="imageLoaded()"
              (cropperReady)="cropperReady()"
              (loadImageFailed)="loadImageFailed()"
              style="width: 100%; height: 100%;"
            >
            </image-cropper>
          </div>
        </div>
      </form>
      <div class="mt-3"></div>
      <mat-radio-group
        (change)="this.levelError = false"
        fxLayoutGap="10px"
        [(ngModel)]="level"
      >
        <mat-radio-button value="Children">Dzieci</mat-radio-button>
        <mat-radio-button value="Other">Młodzież i dorośli</mat-radio-button>
        <mat-radio-button value="Vector">Vector</mat-radio-button>
        <mat-radio-button value="meme">Meme</mat-radio-button>
        <mat-radio-button value="funFact">Fun fact</mat-radio-button>
        <mat-radio-button value="asset">assets</mat-radio-button>
      </mat-radio-group>
    </div>

    <div fxFlex="50">
      <div>
        <div>
          <mat-form-field>
            <mat-label>Dodaj tag (enter)</mat-label>

            <input
              [(ngModel)]="tagToAdd"
              type="text"
              (keydown.enter)="addTag()"
              type="text"
              matInput
            />
            <mat-icon class="pointer" (click)="addTag()" matSuffix
              >add</mat-icon
            >
          </mat-form-field>
        </div>
      </div>
      <div>
        <mat-chip-list>
          <mat-chip
            *ngFor="let tag of tags let t=index"
            class="mr-2"
            color="accent"
            selected
            >{{tag}}
            <mat-icon (dblclick)="removeTag(t)" matChipRemove
              >cancel</mat-icon
            ></mat-chip
          >
        </mat-chip-list>
      </div>
      <hr />
      <div class="mb-2">
        <mat-error *ngIf="tagsError"
          >Musisz dodać co najmniej jeden tag!</mat-error
        >
        <mat-error *ngIf="levelError">Musisz wybrać poziom!</mat-error>
      </div>

      <div fxLayout="row" fxLayoutGap="5px">
        <button
          fxFlex="30"
          color="primary"
          (click)="getImage(croppedImage)"
          mat-stroked-button
        >
          Obrazek gotowy
          <mat-icon *ngIf="addspinner"
            ><mat-spinner color="primary" diameter="20"> </mat-spinner
          ></mat-icon>
        </button>
        <button mat-stroked-button (click)="onPasteClicked()" color="primary">
          Wklej
        </button>

        <!-- <button mat-raised-button  [disabled]="!imageTaken" color="primary" *ngIf="!editMode" size="giant" (click)="onAddClicked(); " ><mat-icon></mat-icon><mat-spinner diameter="20"></mat-spinner>dodaj</button> -->
        <!-- <button mat-raised-button  *ngIf="editMode" size="giant" (click)="onUpdateClicked()" >zapisz</button> -->
        <button
          fxFlex="30"
          [disabled]="!imageTaken"
          color="primary"
          *ngIf="!editMode"
          size="giant"
          (click)="onAddClicked();"
          mat-raised-button
          color="primary"
        >
          Dodaj
        </button>
      </div>
      <div fxLayout="column">
        <span>Big: {{bigImage?.size/1024/1024 | number: '1.2-2'}} MB</span>
        <span>Small: {{smallImage?.size/1024/1024 | number: '1.2-2'}} MB</span>
        <span>Icon: {{iconImage?.size/1024/1024 | number: '1.2-2'}} MB</span>
      </div>
    </div>
  </div>
</mat-card>
