<div class="teachers-evaluation-container d-flex flex-column">
  <h1 style="margin-bottom: 0px">
    {{
      "Teachers Evaluation " + selectedTeacher?.name
        ? selectedTeacher?.name
        : ""
    }}
  </h1>

  <div class="tabs-container-wrapper">
    <div class="tabs-container">
      <div class="teachers-tiles" *ngIf="viewMode === 'all'">
        <div
          class="teacher-tile"
          *ngFor="let teacher of teachers"
          (click)="selectTeacher(teacher); viewMode = 'single'"
        >
          <img
            [src]="teacher.avatar"
            alt="{{ teacher.name }}'s Avatar"
            class="tile-avatar"
          />
          <div class="tile-name">{{ teacher.name }}</div>
        </div>
      </div>
      <button
        *ngIf="viewMode === 'single'"
        (click)="viewMode = 'all'; selectedTeacher = null"
        mat-icon-button
        disableRipple
        style="float: left"
      >
        <mat-icon>arrow_back</mat-icon>
        Back to All Teachers
      </button>

      <app-teacher-tab-content
        *ngIf="selectedTeacher"
        [teacher]="selectedTeacher"
      >
      </app-teacher-tab-content>
    </div>
  </div>
</div>
